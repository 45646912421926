import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";

import HeroRegModal from './HeroRegModal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DemoInstrumentList } from '../../views/examples/DemoInstructorListing.js';

function HeroTop(props){

    let lessonsLandingHeader = React.createRef();

    const [instrumentSel, setInstrumentSel] = React.useState([]);
    const [modal, setModal] = React.useState(true);

    React.useEffect(() => {
        // if (window.innerWidth > 991) {
        //   const updateScroll = () => {
        //     let windowScrollTop = window.pageYOffset / 3;
        //     lessonsLandingHeader.current.style.transform =
        //       "translate3d(0," + windowScrollTop + "px,0)";
        //   };
        //   window.addEventListener("scroll", updateScroll);
        //   return function cleanup() {
        //     window.removeEventListener("scroll", updateScroll);
        //   };
        // }
      });

    const handleSelection = (sel) => {
        setInstrumentSel(sel);
        console.log(sel);    
    }

    const handleSubmit = () => {
            setModal(true);
    }

    return(
        <div className="header-2">
          <div className="page-header lesson-header"
          ref={lessonsLandingHeader}
           >

            <div className="filter" />
            <div className="content-center">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" xs="12">
                    <h1 className="title">One-on-One Music Lessons</h1>
                    <h5 className="description">
                    Start learning from expert instructors today with live in-store or online lessons
                    </h5>
                    <br />
                  </Col>
                  <Col className="ml-auto mr-auto" xs="12" sm="3">
                  <Button block color="danger" type="button" onClick={handleSubmit}>
                                Get Started
                              </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Modal
            isOpen={modal}
            toggle={() => setModal(false)}
            className="modal-register"
            >
                <HeroRegModal modalFn={setModal} instrumentSel={instrumentSel}/>
            </Modal>
        </div>
    );

}

export default HeroTop;