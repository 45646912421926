import React from 'react';
import {
    Row, Col, Button
} from "reactstrap";

const ActionButtonsFrag = ({step, setStep, handleContinue, continueBtnText, optNodalFn, disabled, id}) => {

    var btnText = "Next";
    if(continueBtnText){
        btnText = continueBtnText;
    }

    const handleBack = (e) => {
        if(step >= 1){
            setStep(step - 1);
        }else{
            if(optNodalFn){
                optNodalFn(false);
            }
        }
    }

    return(
              
              <Row className="reg-modal-actions">
                <Col md="3" xs="3">
                    <Button onClick={(e) => handleBack(e)} type="button" className="btn-round">
                    Back
                  </Button>
                </Col>
                <Col className="offset-md-6 offset-5" md="3" xs="4">
                    <Button className="btn-round" type="button" color="danger" id={id ? id : null} onClick={handleContinue} name={btnText} disabled={disabled}>
                        {btnText}
                    </Button>
                </Col>
              </Row>
    );
} 

export default ActionButtonsFrag;