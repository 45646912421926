import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
// import "/assets/demo/demo.css";
import "./assets/demo/react-demo.css";
import "./assets/css/custom-kit.css";
// pages
import Index from "./views/Index.js";
import LessonsLanding from "./gc-views/layout/LessonsLanding";
import NucleoIcons from "./views/NucleoIcons.js";
import Sections from "./views/Sections.js";
import Presentation from "./views/Presentation.js";
import AboutUs from "./views/examples/AboutUs.js";
import AddProduct from "./views/examples/AddProduct.js";
import BlogPost from "./views/examples/BlogPost.js";
import BlogPosts from "./views/examples/BlogPosts.js";
import ContactUs from "./views/examples/ContactUs.js";
import Discover from "./views/examples/Discover.js";
import Ecommerce from "./views/examples/Ecommerce.js";
import Error404 from "./views/examples/Error404.js";
import Error422 from "./views/examples/Error422.js";
import Error500 from "./views/examples/Error500.js";
import LandingPage from "./views/examples/LandingPage.js";
import LoginPage from "./views/examples/LoginPage.js";
import ProductPage from "./views/examples/ProductPage.js";
import ProfilePage from "./views/examples/ProfilePage.js";
import RegisterPage from "./views/examples/RegisterPage.js";
import SearchWithSidebar from "./views/examples/SearchWithSidebar.js";
import Settings from "./views/examples/Settings.js";
import TwitterRedesign from "./views/examples/TwitterRedesign.js";
import InstructorListing from "./gc-views/layout/InstrcutorListing";
import InstructorProfile from "./gc-views/layout/InstructorProfile";
import SampleCartLanding from "./gc-views/sections/SampleCartLanding.js";
import { getQueryStringVal } from "./gc-comps/utility/CommonHandlers.js";

function App() {
  console.log("start-->");
  var source = getQueryStringVal("src");
  if (source === "" || source === null) {
    source = "ma";
  }
  localStorage.setItem("siteSource", source);

  return (
    <div className="App">
      <Switch>
        <Route
          path="/inst-listing"
          render={(props) => <InstructorListing {...props} />}
        />
        <Route
          path="/instructor-profile"
          render={(props) => <InstructorProfile {...props} />}
        />
        <Route path="/" render={(props) => <LessonsLanding {...props} />} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
