import {
    Button,
    CardBody,
    Collapse,
    InputGroup,
    ButtonGroup
  } from "reactstrap";
import React from "react";

class LessonTypeWrapper extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            rSelected : ''
        }
    }
    onCheckboxBtnClick = (selected) => {
    this.setState({
      rSelected: selected.target.value
    })

    var query={};

    if(selected.target.value === '1')
      {
        query =
        {
            "query":
              {
                "bool":{
                  "must":[
                    {
                      "bool":
                      {
                        "must":[
                                {
                                  "script": {
                                    "script": {
                                      "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]);if(en==='Online' || en==='Both'){return true;}}return false;}",
                                      "params": {
                                        "min": 5,
                                        "max": 12
                                      }
                                    }
                                  }
                                }
                            ]
                        }
                      }
                  ]
                }
            }
        }
      }
    if(selected.target.value === '2')
      {
        query =
        {
            "query":
              {
                "bool":{
                  "must":[
                    {
                      "bool":
                      {
                        "must":[
                                {
                                  "script": {
                                    "script": {
                                      "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]);if(en==='Both' || en==='In-Store'){return true;}}return false;}",
                                      "params": {
                                        "min": 13,
                                        "max": 16
                                      }
                                    }
                                  }
                                }
                            ]
                        }
                      }
                  ]
                }
            }
        }
    }
    if(selected.target.value === '3')
    {
        query =
        {
            "query":
              {
                "bool":{
                  "must":[
                    {
                      "bool":
                      {
                        "must":[
                                {
                                  "script": {
                                    "script": {
                                      "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]);if(en==='Both'){return true;}}return false;}",
                                      "params": {
                                        "min": 17,
                                        "max": 23
                                      }
                                    }
                                  }
                                }
                            ]
                        }
                      }
                  ]
                }
            }
        }
     }

     this.props.setQuery({
         query,
         value: ''
     })
     console.log('hello');
    }

    render() {
        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                         <InputGroup className="date" id="preferredTimePicker">
                         <ButtonGroup>
                               <Button name="morning" style={{"background-color": this.state.rSelected === "1"? "#cd2418":"white",
                               color: this.state.rSelected === "1"? "white":"black"}}
                               onClick={this.onCheckboxBtnClick.bind(this)}
                               active={this.state.rSelected === "1"} value="1">Online</Button>
                               <Button name="afternoon" style={{"background-color": this.state.rSelected === "2"? "#cd2418":"white",
                               color: this.state.rSelected === "2"? "white":"black"}}
                               onClick={this.onCheckboxBtnClick.bind(this)}
                               active={this.state.rSelected === "2"} value="2">In-Store</Button>
                               <Button name="evening" style={{"background-color": this.state.rSelected==="3"? "#cd2418":"white",
                               color: this.state.rSelected === "3"? "white":"black"}}
                               onClick={this.onCheckboxBtnClick.bind(this)}
                               active={this.state.rSelected === "3"} value="3">Both</Button>
                         </ButtonGroup>
                        </InputGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default LessonTypeWrapper;