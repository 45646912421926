import React from 'react';
import {
  ReactiveComponent,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Modal,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import Geocode from 'react-geocode';
import InstructorListCard from '../../gc-comps/fragments/InstructorListCard.js';
import InstructorListCardAltOne from '../../gc-comps/fragments/InstructorListCardAltOne.js';
import InstructorContactModal from '../sections/InstructorContactModal.js';
import BookNowModal, {
  STORES_ONLINE_LESSON_ONLY,
} from '../sections/BookNowModal.js';
import GeneralContact from '../sections/GeneralContact.js';
import InstrumentsPickerWrapper from '../../components/ReactiveSearch/InstrumentsPickerWrapper.js';
import DayOfWeekPickerWrapper from '../../components/ReactiveSearch/DayOfWeekPickerWrapper.js';
import TermsWrapper from '../../components/ReactiveSearch/TermsWrapper.js';
import ToggleWrapper from '../../components/ReactiveSearch/ToggleWrapper.js';
import LessonTypeWrapper from '../../components/ReactiveSearch/LessonTypeWrapper.js';
import LocationWrapper from '../../components/ReactiveSearch/LocationWrapper.js';
import Slider from 'nouislider';
import wNumb from 'wnumb';
import { useHistory } from 'react-router-dom';
import { ArrowClockwise } from 'react-bootstrap-icons';
import ProfileModal from '../sections/ProfileModal.js';

function InstructorListing(props) {
  const [contactModal, setContactModal] = React.useState(false);
  const [bookNowModal, setBookNowModal] = React.useState(false);
  const [contactInstructor, setContactInstructor] = React.useState('');
  const [profileModal, setProfileModal] = React.useState(false);

  // focus for inputs
  const [instruments, setInstruments] = React.useState(false);

  const [lessonType, setLessonType] = React.useState(false);
  const [lat, setLat] = React.useState(
    JSON.parse(localStorage.getItem('userSelections')).latitude
      ? JSON.parse(localStorage.getItem('userSelections')).latitude
      : '34.2024698'
  );
  const [long, setLong] = React.useState(
    JSON.parse(localStorage.getItem('userSelections')).longitude
      ? JSON.parse(localStorage.getItem('userSelections')).longitude
      : '-118.8741429'
  );
  const [locQuery, setLocQuery] = React.useState(false);

  const [priceRange, setPriceRange] = React.useState(false);
  const [dayOfWeek, setDayOfWeek] = React.useState(false);
  const [preferredTime, setPreferredTime] = React.useState(false);
  document.documentElement.classList.remove('nav-open');
  const history = useHistory();
  const [locationKeys, setLocationKeys] = React.useState([]);
  window.parent.postMessage({ event: 'InstructorListing', data: {} });

  React.useEffect(() => {
    //            const dummy = getLocationQuery();
    if (
      !document.getElementById('milesslider').classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('milesslider'), {
        start: [25],
        step: 1,
        range: { min: 0, max: 250 },
        tooltips: true,
        format: wNumb({
          decimals: 0,
        }),
        color: 'red',
      });
    }
    document.body.classList.add('ecommerce-page');
    return function cleanup() {
      document.body.classList.remove('ecommerce-page');
    };
  });

  React.useEffect(() => {
    history.listen((loc, action) => {
      if (action === 'POP') {
        window.location.reload();
      }
    });
  }, []);

  function handleContactReq(target, instData) {
    window.parent.postMessage(
      { event: 'InstructorContact', data: instData },
      '*'
    );
    setContactInstructor(instData);
    setContactModal(true);
  }

  function handleBookReq(target, instData) {
    window.parent.postMessage({ event: 'InstructorBook', data: instData }, '*');
    if (profileModal) {
      setProfileModal(false);
    }
    setContactInstructor(instData);
    setBookNowModal(true);
  }

  function handleProfileReq(target, instData) {
    window.parent.postMessage({ event: 'ProfileModal', data: instData }, '*');
    setContactInstructor(instData);
    setProfileModal(true);
  }

  // top bar
  //facets
  //side bar

  //content
  //listing

  function signRequest(request) {
    request.headers = {
      'x-api-key': '7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb',
    };
    request.url =
      'https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api';
    return request;
  }

  function getSortQuery() {
    var query = { match_all: {} };
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).latitude &&
      JSON.parse(localStorage.getItem('userSelections')).latitude.length > 0
    ) {
      query = {
        sort: [
          {
            _geo_distance: {
              storelatlong: {
                lat: JSON.parse(localStorage.getItem('userSelections'))
                  .latitude,
                lon: JSON.parse(localStorage.getItem('userSelections'))
                  .longitude,
              },
              order: 'asc',
              unit: 'mi',
              mode: 'min',
              distance_type: 'arc',
              ignore_unmapped: true,
            },
          },
        ],
      };
    }
    return query;
  }

  function getInstrumentsCustomQuery() {
    var query = { match_all: {} };
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).instrument &&
      JSON.parse(localStorage.getItem('userSelections')).instrument.length > 0
    ) {
      if (
        Array.isArray(
          JSON.parse(localStorage.getItem('userSelections')).instrument
        )
      ) {
        if (
          !JSON.parse(
            localStorage.getItem('userSelections')
          ).instrument.includes('Not sure')
        ) {
          query = {
            terms: {
              'instruments.keyword': JSON.parse(
                localStorage.getItem('userSelections')
              ).instrument,
            },
          };
        }
      } else {
        if (
          !JSON.parse(
            localStorage.getItem('userSelections')
          ).instrument.includes('Not sure')
        ) {
          query = {
            term: {
              'instruments.keyword': JSON.parse(
                localStorage.getItem('userSelections')
              ).instrument,
            },
          };
        }
      }
    }
    return query;
  }

  function getLessonTypeDefaultValues() {
    var val = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
    var filterLabels = [];
    if (Array.isArray(val)) {
      for (let elem of JSON.parse(localStorage.getItem('userSelections'))
        .lessonMode) {
        if (elem === 'In-Store') {
          filterLabels[filterLabels.length] = 'In Person';
        } else {
          filterLabels[filterLabels.length] = elem;
        }
      }
      return filterLabels;
    }
    if (val === 'In-Store') {
      return 'In Person';
    } else {
      return val;
    }
  }

  function getPrefferedTimeFilterLabels() {
    var filterLabels = [];
    for (let val of JSON.parse(localStorage.getItem('userSelections'))
      .timeOfDay) {
      const numValue = parseInt(val);
      if (numValue == 1) {
        filterLabels[filterLabels.length] = 'Morning';
      }
      if (numValue == 2) {
        filterLabels[filterLabels.length] = 'Afternoon';
      }
      if (numValue == 3) {
        filterLabels[filterLabels.length] = 'Evening';
      }
    }
    return filterLabels;
  }

  function getDOWfilterLabels() {
    var filterLabels = [];
    for (let val of JSON.parse(localStorage.getItem('userSelections'))
      .daysOfWeek) {
      const numValue = parseInt(val);
      if (numValue == 1 || val == 'sunday' || val == 'Sunday') {
        filterLabels[filterLabels.length] = 'Sunday';
      }
      if (numValue == 2 || val == 'monday' || val == 'Monday') {
        filterLabels[filterLabels.length] = 'Monday';
      }
      if (numValue == 3 || val == 'tuesday' || val == 'Tuesday') {
        filterLabels[filterLabels.length] = 'Tuesday';
      }
      if (numValue == 4 || val == 'wednesday' || val == 'Wednesday') {
        filterLabels[filterLabels.length] = 'Wednesday';
      }
      if (numValue == 5 || val == 'thursday' || val == 'Thursday') {
        filterLabels[filterLabels.length] = 'Thursday';
      }
      if (numValue == 6 || val == 'friday' || val == 'Friday') {
        filterLabels[filterLabels.length] = 'Friday';
      }
      if (numValue == 7 || val == 'saturday' || val == 'Saturday') {
        filterLabels[filterLabels.length] = 'Saturday';
      }
    }

    return filterLabels;
  }

  function getLessonsTypeCustomQuery() {
    var query = { match_all: {} };
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode.length > 0
    ) {
      if (
        Array.isArray(
          JSON.parse(localStorage.getItem('userSelections')).lessonMode
        )
      ) {
        var checkedBoxes = [];
        var checkedBoxesAct = [];
        var elkey = 'instructorMode.keyword';
        for (let val of JSON.parse(localStorage.getItem('userSelections'))
          .lessonMode) {
          checkedBoxes[checkedBoxes.length] = val;
          if (
            JSON.parse(localStorage.getItem('userSelections')).instrument &&
            Array.isArray(
              JSON.parse(localStorage.getItem('userSelections')).instrument
            ) &&
            JSON.parse(localStorage.getItem('userSelections')).instrument
              .length > 0
          ) {
            for (var ins of JSON.parse(localStorage.getItem('userSelections'))
              .instrument) {
              if (val === 'Online') {
                checkedBoxesAct[checkedBoxesAct.length] = val + ' ' + ins;
              } else {
                checkedBoxesAct[checkedBoxesAct.length] =
                  val + ' ' + ins + ' Lesson';
                checkedBoxesAct[checkedBoxesAct.length] = val + ' ' + ins;
              }
            }
            elkey = 'activityFull.keyword';
          } else if (
            JSON.parse(localStorage.getItem('userSelections')).instrument &&
            JSON.parse(localStorage.getItem('userSelections')).instrument !=
              '' &&
            !Array.isArray(
              JSON.parse(localStorage.getItem('userSelections')).instrument
            )
          ) {
            checkedBoxesAct[checkedBoxesAct.length] =
              val +
              ' ' +
              JSON.parse(localStorage.getItem('userSelections')).instrument;
            checkedBoxesAct[checkedBoxesAct.length] =
              val +
              ' ' +
              JSON.parse(localStorage.getItem('userSelections')).instrument +
              ' Lesson';
            elkey = 'activityFull.keyword';
          } else {
            elkey = 'instructorMode.keyword';
            checkedBoxesAct = JSON.parse(
              localStorage.getItem('userSelections')
            ).lessonMode;
          }
        }
        query = {
          terms: {
            [elkey]: checkedBoxesAct,
          },
        };
      } else {
        var val = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
        var checkedBoxes = [];
        var checkedBoxesAct = [];
        checkedBoxes[checkedBoxes.length] = val;
        if (
          JSON.parse(localStorage.getItem('userSelections')).instrument &&
          Array.isArray(
            JSON.parse(localStorage.getItem('userSelections')).instrument &&
              JSON.parse(localStorage.getItem('userSelections')).instrument
                .length > 0
          )
        ) {
          for (var ins of JSON.parse(localStorage.getItem('userSelections'))
            .instrument) {
            if (val.value === 'Online') {
              checkedBoxesAct[checkedBoxesAct.length] = val + ' ' + ins;
            } else {
              checkedBoxesAct[checkedBoxesAct.length] =
                val + ' ' + ins + ' Lesson';
              checkedBoxesAct[checkedBoxesAct.length] = val + ' ' + ins;
            }
          }
          elkey = 'activityFull.keyword';
        } else if (
          JSON.parse(localStorage.getItem('userSelections')).instrument &&
          JSON.parse(localStorage.getItem('userSelections')).instrument != ''
        ) {
          checkedBoxesAct[checkedBoxesAct.length] =
            val +
            ' ' +
            JSON.parse(localStorage.getItem('userSelections')).instrument;
          checkedBoxesAct[checkedBoxesAct.length] =
            val +
            ' ' +
            JSON.parse(localStorage.getItem('userSelections')).instrument +
            ' Lesson';
          elkey = 'activityFull.keyword';
        } else {
          elkey = 'instructorMode.keyword';
          checkedBoxesAct = checkedBoxes;
        }
        query = {
          terms: {
            [elkey]: checkedBoxesAct,
          },
        };
      }
    }
    return query;
  }

  function getLocationQuery() {
    var query = { match_all: {} };
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).zipCode
    ) {
      query = {
        query: {
          geo_distance: {
            distance: '15 mi',
            storelatlong: {
              lat: JSON.parse(localStorage.getItem('userSelections')).latitude,
              lon: JSON.parse(localStorage.getItem('userSelections')).longitude,
            },
          },
        },
      };
    }
    return query;
  }

  function getPreferredTimeCustomQuery() {
    var query = { match_all: {} };
    var dayOfWeekCheckedBoxesI = [];
    var ltBox = [];
    var ltFromLS = [];
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode.length > 0
    ) {
      if (
        Array.isArray(
          JSON.parse(localStorage.getItem('userSelections')).lessonMode
        )
      ) {
        ltFromLS = JSON.parse(
          localStorage.getItem('userSelections')
        ).lessonMode;
      } else {
        ltFromLS[ltFromLS.length] = JSON.parse(
          localStorage.getItem('userSelections')
        ).lessonMode;
      }
      for (var ltRec of ltFromLS) {
        if (ltRec === 'Online') {
          ltBox[ltBox.length] = 'ONLINE';
        } else {
          ltBox[ltBox.length] = 'INSTORE';
        }
      }
      if (ltBox.length === 0) {
        ltBox = ['INSTORE', 'ONLINE'];
      }
    }

    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).daysOfWeek
    ) {
      var checkedBoxesArray = JSON.parse(
        localStorage.getItem('userSelections')
      ).daysOfWeek;
      var dayOfWeekCheckedBoxes = [];
      var minValue = 8;
      var maxValue = 0;
      for (let val of checkedBoxesArray) {
        dayOfWeekCheckedBoxes[dayOfWeekCheckedBoxes.length] = val;
        let dayEq = '';
        if (val == 'monday' || val == '2') {
          dayEq = '2';
        } else if (val == 'tuesday' || val == '3') {
          dayEq = '3';
        } else if (val == 'wednesday' || val == '4') {
          dayEq = '4';
        } else if (val == 'thursday' || val == '5') {
          dayEq = '5';
        } else if (val == 'friday' || val == '6') {
          dayEq = '6';
        } else if (val == 'saturday' || val == '7') {
          dayEq = '7';
        } else if (val == 'sunday' || val == '1') {
          dayEq = '1';
        }

        const numValue = parseInt(dayEq);
        dayOfWeekCheckedBoxesI[dayOfWeekCheckedBoxesI.length] = numValue;
      }
    }
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).timeOfDay
    ) {
      const tod = JSON.parse(localStorage.getItem('userSelections')).timeOfDay;

      var min = 23;
      var max = 0;
      if (tod.includes('morning') || tod.includes('1')) {
        if (5 < min) {
          min = 5;
        }
        if (12 > max) {
          max = 12;
        }
      }
      if (tod.includes('afternoon') || tod.includes('2')) {
        if (13 < min) {
          min = 13;
        }
        if (16 > max) {
          max = 16;
        }
      }
      if (tod.includes('evening') || tod.includes('3')) {
        if (17 < min) {
          min = 17;
        }
        if (23 > max) {
          max = 23;
        }
      }
      if (tod.length === 0 || (tod.includes('on') && tod.length === 1)) {
        min = 0;
        max = 23;
      }

      query = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  must: [
                    {
                      script: {
                        script: {
                          source:
                            "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                          params: {
                            min: min,
                            max: max,
                            dayOfWeekCheckedBoxesI: dayOfWeekCheckedBoxesI,
                            lt: ltBox,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      };
      if (
        (tod.includes('morning') || tod.includes('1')) &&
        (tod.includes('evening') || tod.includes('3')) &&
        !(tod.includes('afternoon') || tod.includes('2'))
      ) {
        query = {
          query: {
            bool: {
              must: [
                {
                  bool: {
                    should: [
                      {
                        script: {
                          script: {
                            source:
                              "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                            params: {
                              min: 5,
                              max: 12,
                              dayOfWeekCheckedBoxesI: dayOfWeekCheckedBoxesI,
                              lt: ltBox,
                            },
                          },
                        },
                      },
                      {
                        script: {
                          script: {
                            source:
                              "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                            params: {
                              min: 17,
                              max: 23,
                              dayOfWeekCheckedBoxesI: dayOfWeekCheckedBoxesI,
                              lt: ltBox,
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        };
      }
    }
    return query;
  }

  function updateLatLong(lat, long) {
    setLat(lat);
    setLong(long);
  }

  function handleRestart(e) {
    e.preventDefault();
    localStorage.removeItem('userSelections');
    let path = `/lessons-purchase`;
    history.push(path);
  }

  function getDaysOfWeekCustomQuery() {
    var query = { match_all: {} };
    var ltBox = [];
    var ltFromLS = [];
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode &&
      JSON.parse(localStorage.getItem('userSelections')).lessonMode.length > 0
    ) {
      if (
        Array.isArray(
          JSON.parse(localStorage.getItem('userSelections')).lessonMode
        )
      ) {
        ltFromLS = JSON.parse(
          localStorage.getItem('userSelections')
        ).lessonMode;
      } else {
        ltFromLS[ltFromLS.length] = JSON.parse(
          localStorage.getItem('userSelections')
        ).lessonMode;
      }
      for (var ltRec of ltFromLS) {
        if (ltRec === 'Online') {
          ltBox[ltBox.length] = 'ONLINE';
        } else {
          ltBox[ltBox.length] = 'INSTORE';
        }
      }
      if (ltBox.length === 0) {
        ltBox = ['INSTORE', 'ONLINE'];
      }
    }
    if (
      JSON.parse(localStorage.getItem('userSelections')) &&
      JSON.parse(localStorage.getItem('userSelections')).daysOfWeek
    ) {
      var checkedBoxesArray = JSON.parse(
        localStorage.getItem('userSelections')
      ).daysOfWeek;
      var dayOfWeekCheckedBoxes = [];
      var dayOfWeekCheckedBoxesI = [];
      var minValue = 8;
      var maxValue = 0;
      for (let val of checkedBoxesArray) {
        dayOfWeekCheckedBoxes[dayOfWeekCheckedBoxes.length] = val;
        let dayEq = '';
        if (val == 'monday' || val == '2') {
          dayEq = '2';
        } else if (val == 'tuesday' || val == '3') {
          dayEq = '3';
        } else if (val == 'wednesday' || val == '4') {
          dayEq = '4';
        } else if (val == 'thursday' || val == '5') {
          dayEq = '5';
        } else if (val == 'friday' || val == '6') {
          dayEq = '6';
        } else if (val == 'saturday' || val == '7') {
          dayEq = '7';
        } else if (val == 'sunday' || val == '1') {
          dayEq = '1';
        }

        const numValue = parseInt(dayEq);
        dayOfWeekCheckedBoxesI[dayOfWeekCheckedBoxesI.length] = numValue;
        if (numValue < minValue) {
          minValue = numValue;
        }
        if (numValue > maxValue) {
          maxValue = numValue;
        }
      }
      query = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  must: [
                    {
                      script: {
                        script: {
                          source:
                            "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}return false;}",
                          params: {
                            min: dayOfWeekCheckedBoxesI,
                            lt: ltBox,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      };
      if (dayOfWeekCheckedBoxesI.length === 0) {
        query = { match_all: {} };
      }
    }
    return query;
  }

  return (
    <>
      <ReactiveBase
        app='lpp_instructor_master'
        url='https://search-gc-es-487-prod-rhanh3ea25k5getrxi3c3ltqba.us-west-2.es.amazonaws.com'
        headers="{'x-api-key':'7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb','Access-Control-Allow-Origin':true}"
        transformRequest={signRequest}
      >
        <Container fluid className='padded-20-cntn section-lgray'>
          <Row>
            <Col md='3'>
              <Card className='card-refine sticky'>
                <p>Filter By</p>
                <div
                  aria-expanded={true}
                  aria-multiselectable={true}
                  className='panel-group'
                  id='accordion'
                >
                  <CardHeader
                    className='card-collapse force-hide'
                    id='priceRanger'
                    role='tab'
                  >
                    <h5 className='mb-0 panel-title'>
                      <a
                        aria-expanded={priceRange}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setPriceRange(!priceRange);
                        }}
                      >
                        Location <i className='nc-icon nc-minimal-down' />
                      </a>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={priceRange} className='force-hide'>
                    <CardBody>
                      <ReactiveComponent
                        componentId='LocationPicker'
                        customQuery={(props) => ({
                          query: getLocationQuery(),
                        })}
                        react={{
                          and: [
                            'LessonTypePicker',
                            'InstrumentsPicker',
                            'PreferredTimePicker',
                            'DayOfWeekPicker',
                          ],
                        }}
                        render={({ aggregations, setQuery }) => (
                          <LocationWrapper
                            aggregations={aggregations}
                            setQuery={setQuery}
                            handleUpdate={updateLatLong}
                          />
                        )}
                      />
                    </CardBody>
                  </Collapse>

                  <CardHeader
                    className='card-collapse'
                    id='preferredTimeHeader'
                    role='tab'
                  >
                    <h5 className='mb-0 panel-title'>
                      <a
                        aria-expanded={preferredTime}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setPreferredTime(!preferredTime);
                        }}
                      >
                        Preferred Time <i className='nc-icon nc-minimal-down' />
                      </a>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={preferredTime}>
                    <CardBody className='pref-time-pick'>
                      <ReactiveComponent
                        componentId='PreferredTimePicker'
                        customQuery={(props) => ({
                          query: getPreferredTimeCustomQuery(),
                        })}
                        react={{
                          and: [
                            'LessonTypePicker',
                            'InstrumentsPicker',
                            'LocationPicker',
                            'DayOfWeekPicker',
                          ],
                        }}
                        defaultValue={getPrefferedTimeFilterLabels()}
                        filterLabel={'Preferred Time'}
                        render={({ aggregations, setQuery }) => (
                          <ToggleWrapper
                            aggregations={aggregations}
                            setQuery={setQuery}
                            type='checkbox'
                            name='prefTimeCheckbox'
                            elastickey='lessontype'
                          />
                        )}
                      />
                    </CardBody>
                  </Collapse>

                  <CardHeader
                    className='card-collapse'
                    id='dayOfWeekHeader'
                    role='tab'
                  >
                    <h5 className='mb-0 panel-title'>
                      <a
                        aria-expanded={dayOfWeek}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          setDayOfWeek(!dayOfWeek);
                        }}
                      >
                        Day of Week <i className='nc-icon nc-minimal-down' />
                      </a>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={dayOfWeek}>
                    <CardBody>
                      <ReactiveComponent
                        componentId='DayOfWeekPicker'
                        customQuery={(props) => ({
                          query: getDaysOfWeekCustomQuery(),
                        })}
                        react={{
                          and: [
                            'LessonTypePicker',
                            'InstrumentsPicker',
                            'PreferredTimePicker',
                            'LocationPicker',
                          ],
                        }}
                        defaultValue={getDOWfilterLabels()}
                        filterLabel={'Day of Week'}
                        render={({ aggregations, setQuery }) => (
                          <DayOfWeekPickerWrapper
                            aggregations={aggregations}
                            setQuery={setQuery}
                            type='checkbox'
                            name='DayOfWeekPickerCheckbox'
                            elastickey='dayOfWeek'
                          />
                        )}
                      />
                    </CardBody>
                  </Collapse>
                  <GeneralContact />
                </div>
              </Card>
              {/* end card */}
            </Col>
            <ReactiveComponent
              componentId='InstrumentsPicker'
              customQuery={(props) => ({
                query: getInstrumentsCustomQuery(),
              })}
              render={({ aggregations, setQuery }) => null}
            />
            <ReactiveComponent
              componentId='LessonTypePicker'
              customQuery={() => ({
                query: {
                  bool: {
                    must: [getLessonsTypeCustomQuery()],
                  },
                },
              })}
              showFilter={false}
              render={({ aggregations, setQuery }) => null}
            />
            <ReactiveComponent
              componentId='StoreOnlineLessonOnly'
              showFilter={false}
              customQuery={(props) => ({
                query: {
                  bool: {
                    must: [
                      {
                        terms: {
                          storeNumber: STORES_ONLINE_LESSON_ONLY,
                        },
                      },
                    ],
                  },
                },
              })}
              render={({ aggregations, setQuery }) => null}
            />
            <ReactiveComponent
              componentId='Availability'
              showFilter={false}
              customQuery={(props) => ({
                query: {
                  bool: {
                    must: [
                      {
                        exists: {
                          field: 'availability.keyword',
                        },
                      },
                    ],
                  },
                },
              })}
              render={({ aggregations, setQuery }) => null}
            />
            <ReactiveList
              componentId='result'
              title='Results'
              dataField='model'
              from={0}
              size={21}
              defaultQuery={() => ({
                query: {
                  bool: {
                    must: [
                      {
                        match_all: {},
                      },
                    ],
                  },
                },
                sort: [
                  {
                    _geo_distance: {
                      storelatlong: {
                        lat: lat,
                        lon: long,
                      },
                      order: 'asc',
                      unit: 'mi',
                      mode: 'min',
                      distance_type: 'arc',
                      ignore_unmapped: true,
                    },
                  },
                ],
                script_fields: {
                  distance: {
                    script:
                      "(doc['storelatlong'].arcDistance(" +
                      lat +
                      ', ' +
                      long +
                      ') * 5)/8000',
                  },
                },
              })}
              pagination={true}
              className='col-md-9 inst-listing-results'
              react={{
                and: [
                  {
                    and: [
                      'PreferredTimePicker',
                      'DayOfWeekPicker',
                      'InstrumentsPicker',
                      'Availability',
                    ],
                  },
                  {
                    or: [
                      {
                        and: ['LessonTypePicker', 'LocationPicker'],
                      },
                      {
                        and: ['StoreOnlineLessonOnly'],
                      },
                    ],
                  },
                ],
              }}
              loader={
                <div className={`ldOverlay dBlock`}>
                  <div className='uil-reload-css reload-background reload-small mr-1 icLoader'>
                    <div />
                  </div>
                </div>
              }
              renderPagination={({
                pages,
                totalPages,
                currentPage,
                setPage,
                fragmentName,
              }) => {
                const selectPage = Number.isFinite(pages) && (
                  <div class='pagination-div'>
                    {totalPages > 1 ? (
                      <a
                        class='pagination-button'
                        disabled=''
                        tabindex='-1'
                        href={'?result=' + currentPage}
                      >
                        Prev
                      </a>
                    ) : (
                      ''
                    )}
                    {new Array(pages).fill(currentPage).map((item, i) =>
                      item + i + 1 > totalPages ? null : (
                        <a
                          class={
                            'pagination-button' +
                            (currentPage === Number(item + i) ||
                            (currentPage == 0 && i == 0)
                              ? '-active'
                              : '')
                          }
                          tabindex='0'
                          href={'?result=' + (i + 1 + item)}
                          alt={'Page ' + (i + 1 + item)}
                        >
                          {i + 1 + item}
                        </a>
                      )
                    )}
                    {totalPages > 1 ? (
                      <a
                        class='pagination-button'
                        disabled=''
                        tabindex='-1'
                        href={'?result=' + (currentPage + 2)}
                      >
                        Next
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                );

                return selectPage;
              }}
              renderResultStats={function (stats) {
                return (
                  <>
                    <Row className='result-stat mx-auto'>
                      <Col>
                        <div>
                          Showing{' '}
                          <span className='result-stat-count'>
                            {stats.numberOfResults}{' '}
                            {
                              JSON.parse(localStorage.getItem('userSelections'))
                                .instrument
                            }
                          </span>{' '}
                          {stats.numberOfResults > 1
                            ? 'Instructors'
                            : 'Instructor'}
                        </div>
                      </Col>
                      <Col>
                        <Button
                          type='submit'
                          className='btn btn-primary pull-right'
                          color='danger'
                          onClick={(e) => handleRestart(e)}
                        >
                          <ArrowClockwise
                            style={{
                              height: '22px',
                              width: '22px',
                              marginRight: '5px',
                              marginLeft: '-6px',
                            }}
                          ></ArrowClockwise>
                          START OVER
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              }}
              render={({ loading, error, data }) => {
                if (!loading) {
                  return (
                    <div>
                      <Col>
                        <SelectedFilters
                          showClearAll={true}
                          clearAllLabel='Clear filters'
                          className='filter-font'
                          onClear={(component, value) => {
                            var ls = JSON.parse(
                              localStorage.getItem('userSelections')
                            );
                            if (component == null) {
                              if (Array.isArray(ls['timeOfDay'])) {
                                ls['timeOfDay'] = '';
                              } else {
                                ls['timeOfDay'] = '';
                              }
                              if (Array.isArray(ls['daysOfWeek'])) {
                                ls['daysOfWeek'] ='';
                                ls['timeOfDay'] = '';
                              } else {
                                ls['daysOfWeek'] = '';
                                ls['timeOfDay'] = '';
                              }
                            }
                            if (component == 'InstrumentsPicker') {
                              if (Array.isArray(ls['instrument'])) {
                                ls['instrument'] = '';
                              } else {
                                ls['instrument'] = '';
                              }
                            }

                            if (component == 'PreferredTimePicker') {
                              if (Array.isArray(ls['timeOfDay'])) {
                                ls['timeOfDay'] = '';
                              } else {
                                ls['timeOfDay'] = '';
                              }
                            }
                            
                            if (component === 'DayOfWeekPicker') {
                              if (Array.isArray(ls['daysOfWeek'])) {
                                ls['timeOfDay'] = '';
                                ls['daysOfWeek'] = '';
                              } else {
                                ls['timeOfDay'] = '';
                                ls['daysOfWeek'] = '';
                              }
                            }

                            localStorage.setItem(
                              'userSelections',
                              JSON.stringify(ls)
                            );

                            window.location.reload();
                          }}
                        />
                        <div className='products inst-list'>
                          <Row>
                            {data.map((value, index) => {
                              if (value.id) {
                                return (
                                  <Col
                                    md='6'
                                    xs='12'
                                    lg='4'
                                    key={`col-${value.id}`}
                                    id={`col-${value.id}`}
                                  >
                                    <InstructorListCardAltOne
                                      profileModalTrigger={handleProfileReq}
                                      contactModalTrigger={handleContactReq}
                                      bookModalTrigger={handleBookReq}
                                      instData={value}
                                    />
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                        </div>
                      </Col>
                      <Modal
                        isOpen={contactModal}
                        toggle={() => setContactModal(false)}
                        className='modal-register'
                      >
                        <InstructorContactModal
                          modalFn={setContactModal}
                          instructor={contactInstructor}
                        />
                      </Modal>
                      <Modal
                        isOpen={profileModal}
                        toggle={() => setProfileModal(false)}
                        className='modal-register profile-page'
                      >
                        <ProfileModal
                          bookModalTrigger={handleBookReq}
                          modalFn={setProfileModal}
                          instructor={contactInstructor}
                        />
                      </Modal>
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              }}
              renderNoResults={function () {
                const instrument = JSON.parse(
                  localStorage.getItem('userSelections')
                ).instrument;
                return (
                  <Row
                    style={{
                      top: '50px',
                      position: 'absolute',
                      marginLeft: '10px',
                      fontSize: '20px',
                      fontWeight: '500',
                      width: '95%',
                    }}
                    className='noResultsDivMargin'
                  >
                    <Col md={8}>
                      <div>
                        No Instructors Found near{' '}
                        {
                          JSON.parse(localStorage.getItem('userSelections'))
                            .zipCode
                        }
                        .{' '}
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            var ls = JSON.parse(
                              localStorage.getItem('userSelections')
                            );
                            if (Array.isArray(ls['timeOfDay'])) {
                              ls['timeOfDay'] = '';
                            } else {
                              ls['timeOfDay'] = '';
                            }
                            if (Array.isArray(ls['daysOfWeek'])) {
                              ls['daysOfWeek'] = '';
                            } else {
                              ls['daysOfWeek'] = '';
                            }

                            localStorage.setItem(
                              'userSelections',
                              JSON.stringify(ls)
                            );

                            window.location.reload();
                          }}
                          style={{ color: '#cd2418' }}
                        >
                          Click here
                        </a>{' '}
                        to get the list of all{' '}
                        {instrument[0] === 'Not sure' ? '' : instrument}{' '}
                        Instructors
                      </div>
                    </Col>
                    <Col>
                      <Button
                        type='submit'
                        className='btn btn-primary pull-right'
                        color='danger'
                        onClick={(e) => handleRestart(e)}
                      >
                        <ArrowClockwise
                          style={{
                            height: '22px',
                            width: '22px',
                            marginRight: '5px',
                            marginLeft: '-6px',
                          }}
                        ></ArrowClockwise>
                        START OVER
                      </Button>
                    </Col>
                  </Row>
                );
              }}
            />
            <Modal
              isOpen={bookNowModal}
              toggle={() => setBookNowModal(false)}
              className='modal-book-now'
            >
              <BookNowModal
                modalFn={setBookNowModal}
                instructor={contactInstructor}
              />
            </Modal>                                          
          </Row>
        </Container>
      </ReactiveBase>
    </>
  );
}

export default InstructorListing;
