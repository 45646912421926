import React from 'react';
import {
  FormGroup,
  Input,
  Button,
  Label,
  Row,
  Col,
  Collapse,
  Modal,
  FormFeedback,
  UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
import CustomCalendar from '../../gc-comps/fragments/CustomCalendar.js';
import { useHistory } from 'react-router-dom';
import {
  validateFormFields,
  getSelectedWeekdayCount,
} from '../../gc-comps/utility/CommonHandlers.js';
import axios from 'axios';
import config from '../../config.js';
import TermsModal from '../../gc-comps/fragments/TermsModal.js';
import moment from 'moment-timezone';

export const STORES_ONLINE_LESSON_ONLY = ["8550", "7346", "3021", "8901"]

const lessonClosedTerms = new Event('LESSON_CLOSED_TERMS');
var termsModalEvent = {
  event: 'LESSON_CLOSED_TERMS',
};

function BookNowModal(props) {

  const timeThreshold = new Date(new Date().getTime() + 48 * 60 * 60 * 1000);
  const filteredAvailability = props.instructor.availability.filter(
    (timeSlot) => {
      const startTimeStr = timeSlot.split(';')[0];
      const startTime = new Date(startTimeStr);
      return startTime > timeThreshold;
    }
  );
  props.instructor.availability = filteredAvailability;
  
  const instrumentSet = props.instructor.instruments.map((value, index) => {
    return { value: value, label: value };
  });
  var selectedInstrument = {};
  var vUserSelections = JSON.parse(localStorage.getItem('userSelections'));
  if (
    vUserSelections &&
    vUserSelections.instrument &&
    vUserSelections.instrument.length > 0
  ) {
    var instumentsSel = vUserSelections.instrument;
    if (instumentsSel.length === 1 && instumentsSel[0] !== 'Not sure') {
      selectedInstrument = { value: instumentsSel[0], label: instumentsSel[0] };
    }
  }
  const _30price = parseFloat(props.instructor['thirtyprice']);
  const _60price = parseFloat(props.instructor['sixtyprice']);
  const [instrument, setInstrument] = React.useState(
    selectedInstrument && selectedInstrument.value
      ? selectedInstrument
      : instrumentSet[0]
  );

  var defaultMode =
    props.instructor.activityFull &&
    (props.instructor.activityFull.includes('In-Store ' + instrument.value) ||
      props.instructor.activityFull.includes(
        'In-Store ' + instrument.value + ' Lesson'
      ))
      ? 'in-store'
      : 'online';
  if (vUserSelections && vUserSelections.lessonMode) {
    var userSelMode = vUserSelections.lessonMode;
    if (!Array.isArray(userSelMode)) {
      userSelMode = [userSelMode];
    }

    if (userSelMode.length === 1) {
      defaultMode = userSelMode[0].toLowerCase();
    }
  }

  const priceMap = {
    30: _30price,
    60: _60price,
  };
  var allowToSchedule = props.instructor.allowToSchedule;
  if (!allowToSchedule) {
    var enableStoreList = process.env.REACT_APP_ENABLE_STORE_LIST;
    if (
      enableStoreList &&
      enableStoreList.length > 0 &&
      enableStoreList.includes(',')
    ) {
      var storeListArr = enableStoreList.split(',');
      allowToSchedule = storeListArr.includes(props.instructor.storeNumber);
    }
  }

  const defaultAtcErrorMsg = 'Uh Oh! Something went wrong. Please try again.';

  const [firstNameValid, setFirstNameValid] = React.useState('');
  const [lastNameValid, setLastNameValid] = React.useState('');
  const [emailValid, setEmailValid] = React.useState('');

  const [bookStep, setBookStep] = React.useState(2);
  const [duration, setDuration] = React.useState('30');
  const [count, setCount] = React.useState(4);
  const [price, setPrice] = React.useState(priceMap[duration]);
  const [timeSlot, setTimeSlot] = React.useState('');
  const [time, setTime] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [finalAction, setFinalAction] = React.useState(
    allowToSchedule ? 'SCHEDULE' : 'ADD TO CART'
  );
  const [instInvalid, setInstInvalid] = React.useState(false);
  const [scheduleNow, setScheduleNow] = React.useState(
    allowToSchedule ? 'yes' : 'later'
  );
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [contactInstructor, setContactInstructor] = React.useState(
    props.instructor
  );
  const [lessonType, setLessonType] = React.useState(defaultMode);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [custEmail, setCustEmail] = React.useState('');
  const [gradeLevel, setGradeLevel] = React.useState('');
  const [formValid, setFormValid] = React.useState(true);
  const [studentTaking, setStudentTaking] = React.useState('myself');
  const [termsModal, setTermsModal] = React.useState(false);
  const [utcFormatStartTime, setUtcFormatStartTime] = React.useState('');
  const [ackMsg, setAckMsg] = React.useState('success');
  const [sLoading, setSLoading] = React.useState(false);
  const [atcErrorMessage, setAtcErrorMessage] = React.useState('');
  const [readableSlotSel, setReadableSlotSel] = React.useState('');
  const [selWeekdayCount, setSelWeekdayCount] = React.useState('');
  const [termsAccepted, setTermsAccepted] = React.useState(
    finalAction === 'SCHEDULE' ? true : false
  );
  const [timeUnavailable, setTimeUnavailable] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (
        event.origin === 'https://mac-test-virto.miretail.com' ||
        event.origin === 'https://www.musicarts.com' ||
        (process.env.REACT_APP_WORK_ENV === 'test' &&
          event.origin === 'http://localhost:3000')
      ) {
        //valid origins
        if (event.data.event === 'maAddToCartError') {
          console.log(
            'Error event encountered :: ',
            JSON.stringify(event.data, null, 2)
          );
          if (event.data.data && event.data.data.message) {
            setAtcErrorMessage(event.data.data.message);
          } else {
            setAtcErrorMessage(defaultAtcErrorMsg);
          }
          setSLoading(false);
          setAckMsg('failure');
        }
      } else {
        console.warn('ECF_INTD_AMS', ' :: Unauthorized access');
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      firstNameValid === 'valid' &&
      lastNameValid === 'valid' &&
      emailValid === 'valid'
    ) {
      switch (studentTaking) {
        case 'child':
          if (validateFormFields(gradeLevel, 'custName') === 'valid') {
            setFormValid(true);
          } else {
            setFormValid(false);
          }
          break;
        default:
          setGradeLevel('');
          setFormValid(true);
      }
    } else {
      setFormValid(false);
    }
  }, [
    firstNameValid,
    lastNameValid,
    emailValid,
    gradeLevel,
    studentTaking,
    termsAccepted,
  ]);

  const handleSelection = ({ target }) => {
    console.log(target);
    if (target.name === 'lessonDuration') {
      setDuration(target.value);
      setPrice(priceMap[target.value]);
      setStartDate('');
      setDesc('');
      setTimeSlot('');
      if (scheduleNow === 'yes') {
        setFinalAction('SCHEDULE');
        setTermsAccepted(true);
      } else {
        setFinalAction('ADD TO CART');
        setTermsAccepted(false);
      }
    } else if (target.name === 'next1') {
      setBookStep(2);
    } else if (target.name === 'next2') {
      setTermsModal(true);
    } else if (target.name === 'schdl') {
      setScheduleModal(true);
    } else if (target.name === 'scheduleNow') {
      setScheduleNow(target.value);
      if (target.value === 'yes' && !timeSlot) {
        setFinalAction('SCHEDULE');
        setTermsAccepted(true);
        var atcEvent = {
          event: 'LESSON_ACCEPT_TERMS',
        };
        if (process.env.REACT_APP_WORK_ENV === 'test') {
          console.log('In Test :: Req :: ', JSON.stringify(atcEvent, null, 2));
        }
        window.parent.postMessage(atcEvent, '*');
      } else {
        setFinalAction('ADD TO CART');
        setTermsAccepted(false);
      }
    } else if (target.name === 'lessonCount') {
      setCount(target.value);
      var lessonPrice = priceMap[duration];
      setPrice(lessonPrice * target.value);
    } else if (target.value === 'in-store') {
      setLessonType('in-store');
      setStartDate('');
      setDesc('');
      setTimeSlot('');
      if (scheduleNow === 'yes') {
        setFinalAction('SCHEDULE');
        setTermsAccepted(true);
      } else {
        setFinalAction('ADD TO CART');
        setTermsAccepted(false);
      }
    } else if (target.value === 'online') {
      setLessonType('online');
      setStartDate('');
      setDesc('');
      setTimeSlot('');
      if (scheduleNow === 'yes') {
        setFinalAction('SCHEDULE');
        setTermsAccepted(true);
      } else {
        setFinalAction('ADD TO CART');
        setTermsAccepted(false);
      }
    } else if (target.name === 'first-name') {
      setFirstName(target.value);
      setFirstNameValid(validateFormFields(target.value, 'custName'));
    } else if (target.name === 'last-name') {
      setLastName(target.value);
      setLastNameValid(validateFormFields(target.value, 'custName'));
    } else if (target.name === 'student-taking') {
      setStudentTaking(target.value);
    } else if (target.name === 'cust-email') {
      setCustEmail(target.value);
      if (target.classList.contains('is-invalid')) {
        setEmailValid(validateFormFields(target.value, 'custEmail'));
      }
    } else if (target.name === 'grade-level') {
      setGradeLevel(target.value);
    } else if (target.name === 'termsAccepted') {
      //console.log("target.value :: ", target.checked);
      if (finalAction === 'ADD TO CART') {
        setTermsAccepted(target.checked);
      } else {
        setTermsAccepted(true);
      }
    }
  };
const acceptTerms = () => {
  setTermsAccepted(true)
  setBookStep(3);
  var atcEvent = {
    event: 'LESSON_ADD_TO_CART',
  };
  if (process.env.REACT_APP_WORK_ENV === 'test') {
    console.log('In Test :: Req :: ', JSON.stringify(atcEvent, null, 2));
  }

  axios
    .post(
      'https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api',
      '{"preference":"result"}\n' +
        '{"query": {"match": { "instructorId.keyword":"' +
        props.instructor.instructorId +
        '"} } }\n',
      {
        headers: {
          'x-api-key': '7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb',
        },
      }
    )
    .then((res) => {
      if (scheduleNow === 'later') {
        window.parent.postMessage(atcEvent, '*');
        return routeChange();
      }
      const availableTimes =
        res?.data?.responses?.[0]?.hits?.hits?.[0]?._source?.availability;
      const formatStr = 'YYYY-MM-DDTHH:mm';
      const lessons = [];
      const startTime = moment(startDate + ' ' + time);
      lessons.push(
        `${startTime.format(formatStr)};${moment(startTime)
          .add(30, 'minutes')
          .format(formatStr)}`
      );
      if (duration === '60') {
        lessons.push(
          `${moment(startTime)
            .add(30, 'minutes')
            .format(formatStr)};${moment(startTime)
            .add(60, 'minutes')
            .format(formatStr)}`
        );
      }

      const selectedTimeIsAvailable = lessons.every((lessonTime) =>
        availableTimes.some((t) => t.startsWith(lessonTime))
      );
      if (selectedTimeIsAvailable) {
        window.parent.postMessage(atcEvent, '*');
        routeChange();
      } else {
        setTimeUnavailable(true);
      }
    })
    .catch((e) => {
      window.parent.postMessage(atcEvent, '*');
      routeChange();
    });
  };

  const handleInstValidation = (value) => {
    if (value) {
      setInstrument(value);
      setInstInvalid(false);
    } else {
      setInstrument('');
      setInstInvalid(true);
    }
  };

  const handleEventClick = (event) => {
    var jsonStr = JSON.stringify(event.event, null, 2);
    var jsonObj = JSON.parse(jsonStr);
    var tzTimne = moment.tz(jsonObj.start, props.instructor.timezone);
    var utcFormatStr = moment(tzTimne).utc().format();
    var day = moment(jsonObj.start).format('dddd, MMM Do, h:mm A');
    var time = moment(jsonObj.start).format('HH:mm');
    var startDate = moment(jsonObj.start).format('MM/DD/yyyy');
    var desc =
      'Lesson Every ' +
      moment(jsonObj.start).format('dddd') +
      ' between ' +
      moment(jsonObj.start).format('h:mm A') +
      ' - ' +
      moment(jsonObj.end).format('h:mm A');
    setStartDate(startDate);
    setDesc(desc);
    setTimeSlot(day);
    setTime(time);
    setTermsAccepted(false);
    setSelWeekdayCount(getSelectedWeekdayCount(startDate));
    setFinalAction('ADD TO CART');
    setScheduleModal(false);
    setContactInstructor(props.instructor);
    setUtcFormatStartTime(utcFormatStr);
    setReadableSlotSel(
      `${startDate}_${moment(jsonObj.start).format('hh:mm A')}-${moment(
        jsonObj.end
      ).format('hh:mm A')}`
    );
  };

  const history = useHistory();

  const routeChange = async () => {
    var redirectPath = process.env.REACT_APP_ATG_HOST + '/cart';
    var atcPostPath = `${process.env.REACT_APP_ATG_HOST}/storefrontapi/cart/lesson`;
    var lRate = _30price;
    var selLessonType = lessonType;
    if (lessonType === 'in-store') {
      selLessonType = 'inStore';
    }
    setSLoading(true);
    //reset
    setAckMsg('success');
    if (duration == 60) {
      lRate = _60price;
    }
    var respData = {};
    var vGradeLevel = gradeLevel;
    if (studentTaking === 'myself' || studentTaking === 'other') {
      vGradeLevel = 'Adult';
    }
    //        try{
    //            var resp = await axios.get(process.env.REACT_APP_ATG_HOST+'/storefrontapi/lessons/types', {timeout : 3000});
    //            if(resp && resp.data){
    //                respData = resp.data;
    //            }else{
    //                respData = config.lessonTypesMapping;
    //            }
    //        }catch(excp){
    //            console.log("ECF_INTD");
    //            respData = config.lessonTypesMapping;
    //        }
    var lessonID = '';
    //        if(respData && respData.length > 0){
    //            for(var lData of respData){
    //                if(instrument.value === lData.name){
    //                    lessonID = lData.id;
    //                    break;
    //                }
    //            }
    //        }
    var atcReqData = {
      duration: duration,
      lessonId: lessonID,
      lessonRate: lRate,
      level: props.instructor.title,
      locationType: selLessonType,
      storeNumber: props.instructor.storeNumber,
      teacherEmployeeId: props.instructor.id,
      studentName: `${firstName} ${lastName}`,
      startDateTime: utcFormatStartTime,
      teacherName: props.instructor.name,
      lessonFor: studentTaking,
      grade: vGradeLevel,
      quantity: selWeekdayCount,
      preferredDaysOfWeek: getDaysOfWeekStrings(vUserSelections.daysOfWeek),
      preferredTimes: getTimesOfDayStrings(vUserSelections.timeOfDay),
    };

    var atcEvent = {
      event: 'maAddToCart',
      data: atcReqData,
    };
    //        if(process.env.REACT_APP_WORK_ENV === 'test'){
    //            console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
    //        }
    //        window.parent.postMessage(atcEvent, '*');

    var student_details_params = `&first_name=${firstName}&last_name=${lastName}&cust_email=${custEmail}&lesson_for=${studentTaking}`;
    const lessonTypeByStore =
      STORES_ONLINE_LESSON_ONLY.indexOf(props.instructor.storeNumber) === -1
        ? 'in-store'
        : 'online';
    let path =
      process.env.REACT_APP_ATG_HOST +
      '/api/redirection/cart?dcs_action=additemtocart&appointmentTime=' +
      time +
      '&lessonType=' +
      lessonTypeByStore +
      '&instructorInternalID=' +
      props.instructor.instructorId +
      '&url_catalog_ref_id=' +
      props.instructor.sixtyskuId +
      '&url_product_id=' +
      props.instructor.sixtyproductId +
      '&url_quantity=1&&commerce_type=New&lesson_store_id=' +
      props.instructor.storeNumber +
      '&lesson_instrument=' +
      instrument.value +
      '&lesson_duration=' +
      duration +
      '&is_scheduled_item=true&schedule_start_date=' +
      startDate +
      '&scheduled_description=' +
      desc +
      student_details_params +
      '&teacher_name=' +
      props.instructor.name;
    if (duration == 30) {
      path =
        process.env.REACT_APP_ATG_HOST +
        '/api/redirection/cart?dcs_action=additemtocart&appointmentTime=' +
        time +
        '&lessonType=' +
        lessonTypeByStore +
        '&instructorInternalID=' +
        props.instructor.instructorId +
        '&url_catalog_ref_id=' +
        props.instructor.thirtyskuId +
        '&url_product_id=' +
        props.instructor.thirtyproductId +
        '&url_quantity=1&&commerce_type=New&lesson_store_id=' +
        props.instructor.storeNumber +
        '&lesson_instrument=' +
        instrument.value +
        '&lesson_duration=' +
        duration +
        '&is_scheduled_item=true&schedule_start_date=' +
        startDate +
        '&scheduled_description=' +
        desc +
        student_details_params +
        '&teacher_name=' +
        props.instructor.name;
    }
    window.location.href = path;
    //console.log("Request :: ", JSON.stringify(atcReqData, null, 2));
    //window.location.href=redirectPath;
  };

  const timeOfDayMap = {
    1: 'morning',
    2: 'afternoon',
    3: 'evening',
  };

  const dayOfWeekMap = {
    1: 'sunday',
    2: 'monday',
    3: 'tuesday',
    4: 'wednesday',
    5: 'thursday',
    6: 'friday',
    7: 'saturday',
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
  };

  function getTimesOfDayStrings(timesOfDayIndArr) {
    var result = [];
    if (timesOfDayIndArr && timesOfDayIndArr.length > 0) {
      for (var ind of timesOfDayIndArr) {
        result.push(timeOfDayMap[ind]);
      }
    }
    return result;
  }

  function getDaysOfWeekStrings(daysOfWeekIndArr) {
    var result = [];
    if (daysOfWeekIndArr && daysOfWeekIndArr.length > 0) {
      for (var ind of daysOfWeekIndArr) {
        result.push(dayOfWeekMap[ind]);
      }
    }
    return result;
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#DDDDDD'
          : isFocused
          ? '#F5F5F5'
          : null,
        color: 'black',
        fontWeight: 500,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? '#DDDDDD' : '#F5F5F5'),
        },
      };
    },
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
  };

  const hasImage =
    props.instructor.imagename &&
    props.instructor.imagename !==
      'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png';
  const hideInstructorName = !props.instructor.shortbio && !hasImage;
  const storeAddress = props.instructor.storeaddress2
    ? `${props.instructor.storeaddress1} ${props.instructor.storeaddress2}`
    : props.instructor.storeaddress1;
  const instructorStore = `${props.instructor.storecity} - ${storeAddress}, ${props.instructor.storecity}, ${props.instructor.storestate} ${props.instructor.storezip}`;
  const lessonLocation = lessonType === 'in-store' ? instructorStore : 'online';

  return (
    <>
      <div className={`ldOverlay ${sLoading ? 'dBlock' : 'dNone'}`}>
        <div className='uil-reload-css reload-background reload-small mr-1 icLoader'>
          <div />
        </div>
      </div>
      <div className='modal-header no-border-header text-center'>
        <button
          className='close mdl-close'
          type='button'
          onClick={() => props.modalFn(false)}
        >
          <span>×</span>
        </button>
        <h4 className='modal-title text-center contact-modal'>
          Take a {instrument.value} Lesson with{' '}
          {hideInstructorName
            ? 'Music & Arts Instructor'
            : props.instructor.name}
        </h4>
      </div>
      <div className='modal-body' style={{ height: '100%' }}>
        <h6
          style={{ display: ackMsg === 'success' ? 'none' : '' }}
          className='ackSec lpp-mb-1r'
        >
          <span className={`subAck ackFailure`}>{atcErrorMessage}</span>
        </h6>
        <Row>
          <Col md='6' xs='12'>
            <FormGroup>
              <Row className='ml-auto'>
                <Label className='font-weight-bold'>Location</Label>
              </Row>
              <Row className='dur-opt'>
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label className='dow-lbl' style={{ fontWeight: '500' }}>
                      {lessonLocation}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="instrumentSelect"
                                    value={instrument}
                                    onChange={(value) => handleInstValidation(value)}
                                    options={instrumentSet}
                                    placeholder="Choose Instrument"
                                    isClearable={true}
                                    styles={colourStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    menuPlacement='bottom'
                                    menuShouldScrollIntoView={false}
                                />
                                <Input className="input-req-ph" invalid={instInvalid} />
                                <FormFeedback>Please select an instrument to proceed</FormFeedback> */}
            </FormGroup>
          </Col>
          <Col md='6' xs='12'>
            <Row className='ml-auto'>
              <Label className='font-weight-bold'>Duration</Label>
            </Row>
            <Row className='dur-opt'>
              <Col className='pad-r-0'>
                <FormGroup check inline style={{ marginRight: 0 }}>
                  <Label check className='dow-lbl'>
                    <Input
                      id='lessonDuration1'
                      name='lessonDuration'
                      type='checkbox'
                      value={'30'}
                      onChange={handleSelection}
                      checked={duration === '30'}
                    />
                    30 mins <span className='form-check-sign' />
                  </Label>
                </FormGroup>
              </Col>
              <Col className='pad-r-0'>
                <FormGroup check inline style={{ marginRight: 0 }}>
                  <Label check className='dow-lbl'>
                    <Input
                      id='lessonDuration2'
                      name='lessonDuration'
                      type='checkbox'
                      value={'60'}
                      onChange={handleSelection}
                      checked={duration === '60'}
                    />
                    60 mins <span className='form-check-sign' />
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Collapse isOpen={bookStep < 2}>
          <Row>
            <Col>
              <Button
                className='btn-round next1-btn'
                color='default'
                href='!#'
                name='cancel1'
                onClick={(e) => {
                  e.preventDefault();
                  props.modalFn(false);
                }}
                disabled={bookStep > 1}
              >
                CANCEL
              </Button>
            </Col>
            <Col>
              <Button
                className='btn-round next1-btn'
                style={{ float: 'right' }}
                color='danger'
                href='!#'
                name='next1'
                onClick={(e) => {
                  e.preventDefault();
                  handleSelection(e);
                }}
                disabled={bookStep > 1 || instInvalid}
              >
                NEXT
              </Button>
            </Col>
          </Row>
        </Collapse>

        <Collapse isOpen={bookStep > 1}>
          <Row className='row-next2'>
            <Col md='6' xs='12'>
              <Row className='ml-auto'>
                <Label className='font-weight-bold'>
                  Billing Type{' '}
                  <a
                    href='!#'
                    onClick={(e) => e.preventDefault()}
                    id='know-more-subscription'
                    className='know-more-subs'
                  >
                    (?)
                  </a>
                </Label>
                <UncontrolledTooltip
                  delay={0}
                  placement='top'
                  target='know-more-subscription'
                >
                  Lesson accounts are set up on EZ pay. Your lesson subscription
                  authorizes Music & Arts lessons to effect payment for approved
                  monthly fees and charges for the duration of monthly lessons
                  through credit card automatic billing.
                </UncontrolledTooltip>
              </Row>
              <Row className='dur-opt'>
                {/* <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount1"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="1"
                                            onChange={handleSelection}
                                            checked={count <= 1}
                                            />
                                            1 Lesson <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col>
                                    <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount2"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="4"
                                            onChange={handleSelection}
                                            checked={count > 1}
                                            />
                                            Subscribe <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col> */}
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label className='dow-lbl subs-ind'>Subscription</Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md='6' xs='12'>
              <Row className='ml-auto'>
                <Label className='font-weight-bold'>Schedule Lesson</Label>
              </Row>
              <Row className='dur-opt'>
                {allowToSchedule && allowToSchedule === true ? (
                  <Col className='pad-r-0'>
                    <FormGroup check inline>
                      <Label check className='dow-lbl'>
                        <Input
                          id='scheduleNow1'
                          name='scheduleNow'
                          type='checkbox'
                          value='yes'
                          onChange={handleSelection}
                          checked={scheduleNow === 'yes'}
                        />
                        Yes <span className='form-check-sign' />
                      </Label>
                    </FormGroup>
                  </Col>
                ) : (
                  <></>
                )}
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label check className='dow-lbl'>
                      <Input
                        id='scheduleNow2'
                        name='scheduleNow'
                        type='checkbox'
                        value='later'
                        onChange={handleSelection}
                        checked={scheduleNow === 'later'}
                      />
                      Later <span className='form-check-sign' />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs='12' md='12'>
              <h4 className='booking-student-section'>Student Information</h4>
            </Col>
            <Col md='6' xs='12'>
              <FormGroup>
                <Row className='ml-auto'>
                  <Label className='font-weight-bold bnReqField'>
                    First Name
                  </Label>
                </Row>
                <Input
                  className='input'
                  type='text'
                  name='first-name'
                  onBlur={(e) =>
                    setFirstNameValid(
                      validateFormFields(e.target.value, 'custName')
                    )
                  }
                  onChange={handleSelection}
                  invalid={firstNameValid === 'invalid'}
                />
                <FormFeedback>Please enter a first name</FormFeedback>
              </FormGroup>
            </Col>
            <Col md='6' xs='12'>
              <FormGroup>
                <Row className='ml-auto'>
                  <Label className='font-weight-bold bnReqField'>
                    Last Name
                  </Label>
                </Row>
                <Input
                  className='input'
                  type='text'
                  name='last-name'
                  onBlur={(e) =>
                    setLastNameValid(
                      validateFormFields(e.target.value, 'custName')
                    )
                  }
                  onChange={handleSelection}
                  invalid={lastNameValid === 'invalid'}
                />
                <FormFeedback>Please enter a last name</FormFeedback>
              </FormGroup>
            </Col>
            <Col md='12' xs='12'>
              <FormGroup>
                <Row className='ml-auto'>
                  <Label className='font-weight-bold bnReqField'>Email</Label>
                </Row>
                <Input
                  className='input'
                  type='text'
                  name='cust-email'
                  onBlur={(e) =>
                    setEmailValid(
                      validateFormFields(e.target.value, 'custEmail')
                    )
                  }
                  onChange={handleSelection}
                  invalid={emailValid === 'invalid'}
                />
                <FormFeedback>Please enter a valid email</FormFeedback>
              </FormGroup>
            </Col>
            <Col md='12' xs='12'>
              <Row className='ml-auto'>
                <Label className='font-weight-bold'>
                  Who is taking this lesson?
                </Label>
              </Row>
              <Row className='dur-opt'>
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label check className='dow-lbl'>
                      <Input
                        id='student-taking1'
                        name='student-taking'
                        type='checkbox'
                        value='myself'
                        onChange={handleSelection}
                        checked={studentTaking === 'myself'}
                      />
                      Myself <span className='form-check-sign' />
                    </Label>
                  </FormGroup>
                </Col>
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label check className='dow-lbl'>
                      <Input
                        id='student-taking2'
                        name='student-taking'
                        type='checkbox'
                        value='other'
                        onChange={handleSelection}
                        checked={studentTaking === 'other'}
                      />
                      Other <span className='form-check-sign' />
                    </Label>
                  </FormGroup>
                </Col>
                <Col className='pad-r-0'>
                  <FormGroup check inline>
                    <Label check className='dow-lbl'>
                      <Input
                        id='student-taking3'
                        name='student-taking'
                        type='checkbox'
                        value='child'
                        onChange={handleSelection}
                        checked={studentTaking === 'child'}
                      />
                      Child <span className='form-check-sign' />
                    </Label>
                  </FormGroup>
                </Col>
                {studentTaking === 'child' ? (
                  <Col md='12' xs='12'>
                    <FormGroup>
                      <Row className='ml-auto'>
                        <Label className='font-weight-bold bnReqField'>
                          Student Grade
                        </Label>
                      </Row>
                      <Input
                        type='select'
                        name='grade-level'
                        value={gradeLevel}
                        onChange={handleSelection}
                      >
                        <option value=''>Select</option>
                        <option>Pre-Kindergarten</option>
                        <option>Kindergarten</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>Adult</option>
                      </Input>
                      <FormFeedback>Please select a grade</FormFeedback>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md='6' xs='9' className='my-auto'>
              <div className='desc-ct'>
                <span>
                  {`${duration} min ${count > 1 ? 'weekly' : ''} ${
                    instrument.value
                  } ${count > 1 ? 'lessons' : 'lesson'}
                                        with ${
                                          hideInstructorName
                                            ? 'Music & Arts Instructor'
                                            : props.instructor.name
                                        } `}
                </span>
                <a
                  href='!#'
                  onClick={(e) => {
                    e.preventDefault();
                    setScheduleModal(true);
                  }}
                  className='know-more-subs'
                >
                  {`${
                    timeSlot && scheduleNow === 'yes'
                      ? (count > 1 ? 'starting on ' : 'on ') + timeSlot
                      : ''
                  }`}
                </a>
              </div>
            </Col>
            <Col md='6' xs='3'>
              <Row>
                <div className='sess-price'>
                  <Label className='font-weight-bold'>Weekly Price</Label>
                  <span>{`$${price}`}</span>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />
          <Collapse isOpen={bookStep >= 2}>
            <Row>
              <Col md='6' xs='4'>
                <Button
                  className='btn-round next1-btn'
                  color='default'
                  href='!#'
                  name='cancel1'
                  onClick={(e) => {
                    e.preventDefault();
                    props.modalFn(false);
                  }}
                  disabled={bookStep > 2}
                >
                  CANCEL
                </Button>
              </Col>
              <Col md='6' xs='8'>
                <Button
                  className='btn-round next1-btn'
                  style={{ float: 'right' }}
                  color='danger'
                  href='!#'
                  name={finalAction === 'SCHEDULE' ? 'schdl' : 'next2'}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelection(e);
                  }}
                  disabled={instInvalid || !formValid}
                >
                  {finalAction}
                </Button>
              </Col>
            </Row>
          </Collapse>
        </Collapse>
        <Modal
          isOpen={scheduleModal}
          toggle={() => setScheduleModal(false)}
          className='modal-schd-now'
        >
          <div className='modal-header no-border-header text-center'>
            <button
              className='close mdl-close'
              type='button'
              onClick={() => setScheduleModal(false)}
            >
              <span>×</span>
            </button>
            <h3 className='modal-title text-center contact-modal'>Schedule</h3>
            <h6 className='modal-title text-center contact-modal'>
              All times in{' '}
              {moment.tz.zone(props.instructor.timezone).abbr(new Date())}
            </h6>
          </div>
          <div
            className='modal-body'
            id='schedule-modal-body'
            style={{ height: '700px', maxHeight: '750px' }}
          >
            <div className='schedule-legend'>
              <p>Available</p>
            </div>
            <div>
              <CustomCalendar
                view='timeGridWeek'
                handleEventClick={handleEventClick}
                instructor={contactInstructor}
                duration={duration}
                lessonType={lessonType}
                storesOnlineLessonOnly={STORES_ONLINE_LESSON_ONLY}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={termsModal}
          toggle={() => {
            window.parent.postMessage(termsModalEvent, '*');
            setTermsModal(false);
          }}
          className='modal-schd-now'
        >
          <div className='modal-header no-border-header text-center'>
            <button
              className='close mdl-close'
              type='button'
              onClick={() => {
                window.parent.postMessage(termsModalEvent, '*');
                setTermsModal(false);
              }}
            >
              <span>×</span>
            </button>
            <h3 className='modal-title text-center contact-modal'>
              Lessons Terms & Conditions
            </h3>
          </div>
          <div
            className='modal-body d-flex flex-column'
            style={{ height: '700px', maxHeight: '750px' }}
          >
            <div className='terms-modal'>
              <TermsModal />
            </div>
<div class="text-right">
            <button className='btn btn-primary mt-4' onClick={() => acceptTerms()}>
              Accept
            </button>
</div>
          </div>
        </Modal>

        <Modal
          size='sm'
          isOpen={timeUnavailable}
          className='time-unavailable-modal'
        >
          <div className='modal-body'>
            <p>
              <strong>
                Your selected time slot is not available. Please select a
                different time for your lessons.
                <br />
                <br />
              </strong>
            </p>
            <Button
              color='primary'
              className='float-right'
              size='normal'
              onClick={() => setTimeUnavailable(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default BookNowModal;
