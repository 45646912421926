import {
    CardBody,
    Collapse,
    Label,
    FormGroup,
    Input
  } from "reactstrap";
import React from "react";

class DayofWeekPickerWrapper extends React.Component {
    constructor()
    {
        super();
        let daysOfWeekLS = [];
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).daysOfWeek)
        {
            daysOfWeekLS=JSON.parse(localStorage.getItem('userSelections')).daysOfWeek;
        }
        this.state = {
            dayOfWeekCheckBoxes:daysOfWeekLS
        }
    }

    onCheckboxBtnClick = (event) => {
        const checkedBoxes = document.querySelectorAll('input[name=dayOfWeekCheckBoxes]:checked');
        var checkedBoxesArray = Array.from(checkedBoxes);
        var dayOfWeekCheckedBoxes = [];
        var dayOfWeekCheckedBoxesI = [];
        var filterLabels = [];
        var minValue=8;
        var maxValue=0;
        var ltBox = [];
        var ltFromLS = [];
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).lessonMode
                    && JSON.parse(localStorage.getItem('userSelections')).lessonMode.length>0)
        {
            if(Array.isArray(JSON.parse(localStorage.getItem('userSelections')).lessonMode))
            {
                ltFromLS = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
            }
            else
            {
                ltFromLS[ltFromLS.length] = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
            }
            for(var ltRec of ltFromLS)
            {
                if(ltRec==='Online')
                {
                    ltBox[ltBox.length]='ONLINE';
                }
                else
                {
                    ltBox[ltBox.length]='INSTORE';
                }
            }
        }

        if(ltBox.length===0)
        {
            ltBox = ["INSTORE","ONLINE"];
        }
        for(let val of checkedBoxesArray)
        {
            dayOfWeekCheckedBoxes[dayOfWeekCheckedBoxes.length]=val.value;
            dayOfWeekCheckedBoxesI[dayOfWeekCheckedBoxesI.length]=parseInt(val.value);
            const numValue = parseInt(val.value);
            if(numValue<minValue)
            {
                minValue = numValue;
            }
            if(numValue>maxValue)
            {
                maxValue = numValue;
            }

            if(numValue==1)
            {
                filterLabels[filterLabels.length]="Sunday";
            }
            if(numValue==2)
            {
                filterLabels[filterLabels.length]="Monday";
            }
            if(numValue==3)
            {
                filterLabels[filterLabels.length]="Tuesday";
            }
            if(numValue==4)
            {
                filterLabels[filterLabels.length]="Wednesday";
            }
            if(numValue==5)
            {
                filterLabels[filterLabels.length]="Thursday";
            }
            if(numValue==6)
            {
                filterLabels[filterLabels.length]="Friday";
            }
            if(numValue==7)
            {
                filterLabels[filterLabels.length]="Saturday";
            }
        }

        var query = {};

        if(checkedBoxes && checkedBoxes.length===0)
        {
            query = {
                query: {
                    "match_all": {}
                }
            };
        }
        else
        {
            query =
            {
                "query":
                  {
                    "bool":{
                      "must":[
                        {
                          "bool":
                          {
                            "must":[
                                    {
                                      "script": {
                                        "script": {
                                          "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}return false;}",
                                          "params": {
                                            "min": dayOfWeekCheckedBoxesI,
                                            "lt": ltBox
                                          }
                                        }
                                      }
                                    }
                                ]
                            }
                          }
                      ]
                    }
                }
            }
        }
        this.setState({dayOfWeekCheckBoxes:dayOfWeekCheckedBoxes});
        var ls = JSON.parse(localStorage.getItem('userSelections'));
        Object.keys(ls).forEach((key) => {
            if('daysOfWeek'==key)
            {
              ls[key] = dayOfWeekCheckedBoxes.length > 0 ? dayOfWeekCheckedBoxes : '';
            }
            if('timeOfDay'==key)
            {
              console.log('timeOfDay', dayOfWeekCheckedBoxes.length);
              ls['timeOfDay'] = dayOfWeekCheckedBoxes.length > 0 ? ['on'] : '';
            }
        });
        
        localStorage.setItem('userSelections', JSON.stringify(ls));
        if(document.getElementById('prxlt'))
        {
            document.getElementById('prxlt').click();
        }
        if(document.getElementById('prxpt'))
        {
            document.getElementById('prxpt').click();
        }
        this.props.setQuery({
            query,
            value: filterLabels
        })
    }

    render() {
        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                        <FormGroup check>
                          <Label check>
                            <Input value="2" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('monday') || this.state.dayOfWeekCheckBoxes.includes('2')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Monday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="3" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('tuesday') || this.state.dayOfWeekCheckBoxes.includes('3')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Tuesday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="4" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('wednesday') || this.state.dayOfWeekCheckBoxes.includes('4')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Wednesday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="5" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('thursday') || this.state.dayOfWeekCheckBoxes.includes('5')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Thursday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="6" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('friday') || this.state.dayOfWeekCheckBoxes.includes('6')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Friday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="7" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('saturday') || this.state.dayOfWeekCheckBoxes.includes('7')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Saturday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input value="1" type="checkbox" name="dayOfWeekCheckBoxes" className="dayOfWeekCheckBoxes" checked={this.state.dayOfWeekCheckBoxes.includes('sunday') || this.state.dayOfWeekCheckBoxes.includes('1')}
                            onChange={this.onCheckboxBtnClick.bind(this)}
                            />
                            Sunday <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                        <Label check>
                               <Input type="checkbox" name="prxdow" id="prxdow" style={{display:'none'}}
                               onChange={this.onCheckboxBtnClick.bind(this)}/>
                        </Label>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default DayofWeekPickerWrapper;
