import React from 'react';
import StepZeroGeneral from './StepZeroGeneral.js';
import StepOneGeneral from './StepOneGeneral.js';
import StepTwoGeneral from './StepTwoGeneral.js';
import StepThreeGeneral from './StepThreeGeneral.js';
import StepFourGeneral from './StepFourGeneral.js';
import StepFiveGeneral from './StepFiveGeneral.js';
import StepSixGeneral from './StepSixGeneral.js';
import StepSevenGeneral from './StepSevenGeneral.js';
import StepEightGeneral from './StepEightGeneral.js';
import StepNineGeneral from './StepNineGeneral.js';
import StepTenGeneral from './StepTenGeneral.js';
import StepElevenGeneral from './StepElevenGeneral.js';
import StepTwelveGeneral from './StepTwelveGeneral.js';
import StepThirteenGeneral from './StepThirteenGeneral.js';
import StepFourteenGeneral from './StepFourteenGeneral.js';
import StepFifteenGeneral from './StepFifteenGeneral.js';
import StepSixteenGeneral from './StepSixteenGeneral.js';
import StepSeventeenGeneral from './StepSeventeenGeneral.js';
import StepEighteenGeneral from './StepEighteenGeneral.js';


export const MinimizedFormSteps = (step, setStep, user, setUser, setRLoading, optNodalFn) => [
    // 0 - instrument - StepZeroGeneral.js
    {
        title : 'Start your Lessons journey now',
        titleChild : 'Start your Lessons journey now',
        titleSomeOne : 'Start your Lessons journey now',
        optional : false,
        displayCount : true,
        content : (
            <StepZeroGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} 
            optional= {false}
            setRLoading= {setRLoading}
            optNodalFn= {optNodalFn}/>
        )
    },
    /* //1 - lessonMode - StepFourteenGeneral.js
    {
        title : 'Preferred style of learning?',
        titleChild : 'Preferred style of learning?',
        titleSomeOne : 'Preferred style of learning?',
        optional : true,
        displayCount : true,
        content : (
            <StepFourteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} 
            optional= {true}
            setRLoading= {setRLoading}
            optNodalFn= {optNodalFn}/>
        )
    },
    //2 - daysOfWeek - StepTenGeneral.js
    {
        title : 'Preferred days of week are',
        titleChild : 'Preferred days of week are',
        titleSomeOne : 'Preferred days of week are',
        optional : true,
        displayCount : true,
        content : (
            <StepTenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} 
            optional= {true}
            setRLoading= {setRLoading}/>
        )
    },
    //3 - timeOfDay - StepElevenGeneral.js
    {
        title : 'Preferred times of day are',
        titleChild : 'Preferred times of day are',
        titleSomeOne : 'Preferred times of day are',
        optional : true,
        displayCount : true,
        content : (
            <StepElevenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} 
            optional= {true}
            setRLoading= {setRLoading}/>
        )
    }, */
    //4 - zipCode - StepEighteenGeneral.js
    {
        title : 'Please specify a location',
        titleChild : 'Please specify a location',
        titleSomeOne : 'Please specify a location',
        optional : false,
        displayCount : true,
        content : (
            <StepEighteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} 
            optional= {false}
            setRLoading= {setRLoading}/>
        )
    }
]

export const FormSteps = (step, setStep, user, setUser) => [
    //0 - lessonFor - StepOneGeneral.js
    {
        title : 'The lesson is for',
        titleChild : 'The lesson is for',
        titleSomeOne : 'The lesson is for',
        content : (
            <StepOneGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //1 - experience - StepTwoGeneral.js
    {
        title : 'I have',
        titleChild : 'My child has',
        titleSomeOne : 'The student has',
        content : (
            <StepTwoGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //2 - readsMusic - StepThreeGeneral.js
    {
        title : 'I can read music',
        titleChild : 'My child can read music',
        titleSomeOne : 'The student can read music',
        content : (
            <StepThreeGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //3 - ageRange - StepFourGeneral.js
    {
        title : 'I am',
        titleChild : 'My child is',
        titleSomeOne : 'The student is',
        content : (
            <StepFourGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //4 - musicStyle - StepFiveGeneral.js
    {
        title : 'I am interested in',
        titleChild : 'My child is interested in',
        titleSomeOne : 'The student is interested in',
        content : (
            <StepFiveGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //5 - hasInstrument - StepSixGeneral.js
    {
        title : 'I already have an instrument',
        titleChild : 'My child already has an instrument',
        titleSomeOne : 'The student already has an instrument',
        content : (
            <StepSixGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //6 - continuity - StepSevenGeneral.js
    {
        title : 'For how long are you interested in taking lessons?',
        titleChild : 'For how long is your child interested in taking lessons?',
        titleSomeOne : 'For how long is the student interested in taking lessons?',
        content : (
            <StepSevenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //7 - frequency - StepEightGeneral.js
    {
        title : 'I would like to meet',
        titleChild : 'I would like for my child to meet',
        titleSomeOne : 'The student would like to meet',
        content : (
            <StepEightGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //8 - duration - StepNineGeneral.js
    {
        title : 'How long the lessons will have to be?',
        titleChild : 'How long the lessons will have to be?',
        titleSomeOne : 'How long the lessons will have to be?',
        content : (
            <StepNineGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //9 - daysOfWeek - StepTenGeneral.js
    {
        title : 'Preferred days of week are',
        titleChild : 'Preferred days of week are',
        titleSomeOne : 'Preferred days of week are',
        content : (
            <StepTenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //10 - timeOfDay - StepElevenGeneral.js
    {
        title : 'Preferred times of day are',
        titleChild : 'Preferred times of day are',
        titleSomeOne : 'Preferred times of day are',
        content : (
            <StepElevenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //11 - startDate - StepTwelveGeneral.js
    {
        title : 'I would like to start',
        titleChild : 'I would like for my child to start',
        titleSomeOne : 'The student would like to start',
        content : (
            <StepTwelveGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //12 - readiness - StepThirteenGeneral.js
    {
        title : 'Where are you in picking an instructor?',
        titleChild : 'Where are you in picking an instructor?',
        titleSomeOne : 'Where are you in picking an instructor?',
        content : (
            <StepThirteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //13 - lessonMode - StepFourteenGeneral.js
    {
        title : 'Preferred mode of learning?',
        titleChild : 'Preferred mode of learning?',
        titleSomeOne : 'Preferred mode of learning?',
        content : (
            <StepFourteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //14 - aboutYou - StepFifteenGeneral.js
    {
        title : 'Please tell us a little bit about yourself',
        titleChild : 'Please tell us a little bit about the student',
        titleSomeOne : 'Please tell us a little bit about the student',
        optional : true,
        content : (
            <StepFifteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //15 - goals - StepSixteenGeneral.js
    {
        title : 'What are the goals for the lessons?',
        titleChild : 'What are the goals for the lessons?',
        titleSomeOne : 'What are the goals for the lessons?',
        optional : true,
        content : (
            <StepSixteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //16 - notes - StepSeventeenGeneral.js
    {
        title : 'Is there anything else the instructor should know?',
        titleChild : 'Is there anything else the instructor should know?',
        titleSomeOne : 'Is there anything else the instructor should know?',
        optional : true,
        content : (
            <StepSeventeenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    },
    //17 - zipCode - StepEighteenGeneral.js
    {
        title : 'Please specify a location',
        titleChild : 'Please specify a location',
        titleSomeOne : 'Please specify a location',
        content : (
            <StepEighteenGeneral setStep={setStep}
            step={step}
            user={user}
            setUser={setUser} />
        )
    }
]