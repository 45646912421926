import React from 'react';
import {
    Row, Col, Form, FormGroup, Input, Button, Label, Card, CardBody, Modal, Container
} from "reactstrap";

import { Typeahead } from 'react-bootstrap-typeahead';
import { DemoInstrumentList } from '../../../views/examples/DemoInstructorListing.js';
import config from '../../../config.js';
import axios from 'axios';


const StepZeroGeneral = ({step, setStep, user, setUser}) => {
    const userInstrument = user && user.synInstrument && user.synInstrument.length ? user.synInstrument : [];
    const [instrumentSel, setInstrumentSel] = React.useState([userInstrument]);

    const[instrumentSetData, setInstrumentSetData] = React.useState([['Guitar'], ['Key/Piano'], ['Ukulele'], ['Drum'], ['Keyboard'], ['Bass'], ['Electric Guitar'], ['Acoustic Guitar'], ['Vocal'], ['Electric Bass'], ['Not sure']]);

    const[instrumentKeyedSetData, setInstrumentKeyedSetData] = React.useState([]);

    React.useEffect(() => {
      getInstructorCounts();
    }, []);

    const handleUser = ({ target }) => {
        setUser({
          type: 'INSTRUMENT',
          payload: { [target.name]: target.value },
        });
      }
    const handleContinue = (e) => {
        e.preventDefault();
        // if(!user.lessonFor){
        //     return;
        // }
        setStep(step+1);
    }

    const handleSelection = (sel) => {
      var tsel = [];
      if(sel && sel.length > 0){
        tsel.push(sel[0].name);
        console.log("InstrumentSel :: ", tsel);
        setUser({
            type: 'INSTRUMENT',
            payload: { instrument: tsel , synInstrument: sel},
          });
      }
      setInstrumentSel([sel]);
      var atcEvent = {
        'event' : 'LESSON_SELECT_INSTRUMENT',
        'data': tsel[0]
      };

      if(process.env.REACT_APP_WORK_ENV === 'test'){
          console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
      }
      window.parent.postMessage(atcEvent, '*');
    }
    const getInstructorCounts = (step) => {
      let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              'x-api-key': '7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb'
          }
        };
      
        let reqData = {
          "size": 0, 
          "aggs": {
            "instr-agg": {
              "terms": {
                "field": "instruments.keyword",
                "size": 100
              }
            }
          }
        }
      
        axios.post('https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api/iquery', reqData, axiosConfig)
        .then(res => {
          if (res.data && res.data.aggregations && res.data.aggregations['instr-agg'] && res.data.aggregations['instr-agg'].buckets) {
            var vBuckets = res.data.aggregations['instr-agg'].buckets;
            var instrmentDataSet = [];
            var instrmentDataKeySet = [];
            var keyedSynDataSet = [];
            for(var bkt of vBuckets){
              //instrmentDataSet.push([bkt.key]);
              var keyedData = {
                "name" : bkt.key,
                "synonym" : bkt.key
              }
              instrmentDataSet.push([bkt.key]);
              instrmentDataKeySet.push(bkt.key);
              keyedSynDataSet.push(keyedData);
            }
            //keyedSynDataSet.push(...config.instrumentSynonyms);
            for(var synInst of config.instrumentSynonyms){
                if(!instrmentDataKeySet.includes(synInst.synonym)){
                  keyedSynDataSet.push(synInst);
                }
            }
            keyedSynDataSet = [...new Set(keyedSynDataSet)];
            setInstrumentSetData(instrmentDataSet);
            setInstrumentKeyedSetData(keyedSynDataSet);
          }
        })
        .catch(err => {
            console.error(err);
           
          }
        );
  }
    // creating array of strings to match Typeahead expected data type 
    // const instrumentList = instrumentSetData.map(item => {
    //   return item[0];
    // });
    const instrumentList = instrumentKeyedSetData;

    const handleSubmit = () => {
      document.getElementById('typeahead-next').click();
    }

    return(
         <Form action="" method="" onSubmit={handleSubmit}>
                          <Row style={{alignItems: "center"}}>
                            <Col className="mb-auto-scr">
                                    {/* <Typeahead
                                        id="basic-instrument-ta"
                                        labelKey="instrument"
                                        onChange={handleSelection}
                                        options={instrumentList}
                                        placeholder="What would you like to learn?"
                                        selected={instrumentSel[0]}
                                        clearButton
                                        className="basic-instrument-ta"
                                        minLength={1}
                                        /> */}
                                        <Typeahead
                                        id="basic-instrument-ta"
                                        labelKey={(option) => `${option.synonym}`}
                                        onChange={handleSelection}
                                        options={instrumentList}
                                        placeholder="What would you like to learn?"
                                        selected={instrumentSel[0]}
                                        clearButton
                                        className="basic-instrument-ta"
                                        minLength={1}
                                        />
                                        <Button block id="typeahead-next" type="submit" className="btn-round btn-danger" color="default" onClick={handleContinue} disabled={instrumentSel[0].length === 0}>
                      Search
                  </Button>
                            </Col>
                            
                          </Row>
                        </Form>
    );
} 

export default StepZeroGeneral;