import React from "react";
import { Row, Col } from "reactstrap";

function ProfileModal(props) {

    const[sLoading, setSLoading] = React.useState(false);


    const hasImage = props.instructor.imagename && (props.instructor.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
    const hideInstructorName = !props.instructor.shortbio && !hasImage;
    const storeAddress = props.instructor.storeaddress2 ? `${props.instructor.storeaddress1} ${props.instructor.storeaddress2}` : props.instructor.storeaddress1;
    const instructorStore = `${props.instructor.storecity} - ${storeAddress}, ${props.instructor.storecity}, ${props.instructor.storestate} ${props.instructor.storezip}`;
    const webpage = props.instructor.webpage ? props.instructor.webpage : `https://stores.musicarts.com/instructors/${props.instructor.firstname.toLowerCase()}-${props.instructor.lastname.toLowerCase()}`
    return(
        <>
        <div className={`ldOverlay ${sLoading ? "dBlock" : "dNone"}`}>
                            <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
                                <div />
                              </div>
                          </div>
                <div className="modal-header no-border-header text-center">
                  <button
                    className="close mdl-close"
                    type="button"
                    onClick={() => props.modalFn(false)}
                  >
                    <span>×</span>
                  </button>
                  {/* <h4 className="modal-title text-center contact-modal">{hideInstructorName ? "Music & Arts Instructor" :props.instructor.name}</h4> */}
                </div>
                <div className="modal-body" style={{height: '100%'}}>
                
                    <Row className="inst-profile-page">
                    <Col className="ml-auto mr-auto left-panel" md="4">
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding text-center">
                  {props.instructor && props.instructor.imagename ?  <img
                          alt="avatar"
                          className="instructor-image"
                          src={props.instructor.imagename}
                        /> : <div className="avatar-placeholder instructor-image"></div>

                        }
                  </div>
                  <div className="name">
                  <div className="instructor-info">
              <h3 className="instructor-info_name">{props.instructor && !hideInstructorName ? props.instructor.name : 'Music & Arts Instructor'}</h3>
            </div>
                    <h4 className="title text-center">
                    Music Teacher, Professional <br />
                    </h4>
                  </div>
                  <div className="card-profile_location">
                    <div className="card-profile_location_icon"></div>
                    <p style={{fontWeight: 500}}><span>{`Music & Arts ${props.instructor.storecity} #${props.instructor.storeNumber}`}</span><br/>
                    <span>{storeAddress}</span><br/><span>{`${props.instructor.storecity}, ${props.instructor.storestate}`}</span></p>
                        </div>
                        {/* <div className="card-profile_website">
                            <a target="_blank" href={webpage}>{`View ${props.instructor.firstname}'s website `}</a>
                            <span>(opens in new tab)</span>
                        </div> */}
                  <Row>
                  <Col className="ml-auto mr-auto button-container" md="12">
                    <button style={{fontWeight: 'bold'}} className="btn btn-primary" onClick={(e) => {e.preventDefault(); props.bookModalTrigger(e, props.instructor)}}
                                            >Book Now</button>
                    {/* <Button
                        className={`btn btn-secondary ${props.toggleContactBtn? "ind-btn-tgl" : ""}`}
                        color="white"
                        href="/instructor-profile"
                        name={props.instructor.name}
                        onClick={(e) => {e.preventDefault(); handleContactReq(e, props.instructor)}}
                        >
                        CONTACT ME
                    </Button> */}
                    {/* <Modal
                        isOpen={contactModal}
                        toggle={() => setContactModal(false)}
                        className="modal-register"
                        >
                            <InstructorContactModal modalFn={setContactModal} instructor={contactInstructor}/>
                        </Modal> */}
                        {/* <Modal
                        isOpen={bookNowModal}
                        toggle={() => setBookNowModal(false)}
                        className="modal-book-now"
                        >
                            <BookNowModal modalFn={setBookNowModal} instructor={props.instructor}/>
                        </Modal> */}
                  </Col>
                </Row>
                <Row>
                </Row>
                
                </div>
              </div>
                <br />
                </Col> 
                <Col className="ml-auto mr-auto bio-panel" md="8">
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                  <p className="info-panel-title">Bio</p>
                  {props.instructor && props.instructor.shortbio ? props.instructor.shortbio.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/&amp;/g, ' & ').replace(/&rsquo;/g, "'") : null}
                  </Col>
                  <div className="basic-info">
                    <div className="basic-info-section">
                      <p className="info-label">Expertise</p>
                      
                      <div className="info-items">
                      {props.instructor && props.instructor.instruments ? props.instructor.instruments.map((item) => {
                                    return (
                                    <span className="info-item">{item}</span>
                                    )
                                }) : null
                              }
                      </div>
                    </div>
                  </div>
                  <div className="lesson-price-panel">
              <p className="info-panel-title">Lessons Price</p>
              <div className="lesson-price-panel_row">
                <div className="lesson-price-panel_col">
                  <p className="panel_bullet-item">30 Min</p>
                  <p className="panel_info">Book four 30 minute lessons</p>
                </div>
                <div className="lesson-price-panel_col">
                  <p className="lesson-price-panel_price">{props.instructor && props.instructor.thirtyprice ? `$${props.instructor.thirtyprice}` : null}</p>
                </div>
              </div>
              <div className="lesson-price-panel_row">
                <div className="lesson-price-panel_col">
                  <p className="panel_bullet-item">60 Min</p>
                  <p className="panel_info">Book four 60 minute lessons</p>
                </div>
                <div className="lesson-price-panel_col">
                  <p className="lesson-price-panel_price">{props.instructor && props.instructor.sixtyprice ? `$${props.instructor.sixtyprice}` : null}</p>
                </div>
              </div>
            </div>
                </Row>
            </Col> 
               
            </Row>
                </div>
        </>
    )
}

export default ProfileModal;