import React, { useReducer, useState, useEffect } from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { UserReducer, DefaultUser } from '../../gc-comps/reducer/UserReducer.js';
import {MinimizedFormSteps} from './lessons-form/FormSteps.js';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {getQueryForDaysOfWeek,getQueryForTimeOfDay} from '../../gc-comps/utility/CommonHandlers.js';
// reactstrap components
import {
  Button,
  Progress,
  Row,
  Col
} from "reactstrap";
function HeroRegModal(props) {
    const [rLoading, setRLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(20);
    let [step, setStep] = useState(0);
    const [user, setUser] = useReducer(UserReducer, DefaultUser);
    const [instructorCountInd, setInstructorCountInd] = React.useState('');
    const [addedInd, setAddedInd] = React.useState('+');
    let steps = MinimizedFormSteps(step, setStep, user, setUser, setRLoading, props.modalFn);
    var instrumentSel = user.instrument.length > 0 ? user.instrument[0] : "";
    React.useEffect(() => {
        setUser({
            type: 'INSTRUMENT',
            payload: { instrument:  instrumentSel},
          });
      }, []);
      React.useEffect(() => {
        getInstructorCounts(step);
      }, [step]);
      React.useEffect(() => {
        setInstructorCountInd(0);
      }, [user.instrument]);
    const history = useHistory();
    const handleJumpToListing = (e) => {
        e.preventDefault();
        localStorage.setItem('userSelections', JSON.stringify(user));
        let path = `inst-listing`; 
        history.push(path);
    }
    const instCount = [30, 20, 15, 10, 5];
    const getInstructorCounts = (step) => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "x-api-key": "7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb"
            }
          };
        
          let reqData = {
            "query": {
              "bool": {
                "must": [
                  {
                    "query_string": {
                        "query": `(${instrumentSel})`,
                        "default_field": "instruments"
                    }
                  }
                ]
              }
            }
          }
          //console.log("Step :: ", step);
          if(step >= 1){
            if(user.lessonMode && user.lessonMode.length > 0){
              var avlInstArr = [`${user.lessonMode} ${instrumentSel}`, `${user.lessonMode} ${instrumentSel} Lesson`];
            }else{
              var avlInstArr = [`In-Store ${instrumentSel}`, `In-Store ${instrumentSel} Lesson`, `Online ${instrumentSel}`, `Online ${instrumentSel} Lesson`];
            }
                  var instArr = [`${instrumentSel}`];
            // reqData.query.bool.must.push({
            //     "query_string": {
            //         "query": `(${user.lessonMode})`,
            //         "default_field": "instructorMode"
            //     }
            //   });
            reqData = {
              "query":{
                 "bool":{
                    "must":[
                       {
                          "bool":{
                             "must":[
                                {
                                   "match_all":{
                                      
                                   }
                                },
                                {
                                   "match_all":{
                                      
                                   }
                                },
                                {
                                   "terms":{
                                      "activityFull.keyword":avlInstArr
                                   }
                                },
                                {
                                   "terms":{
                                      "instruments.keyword":instArr
                                   }
                                },
                                {
                                   "bool":{
                                      "must":[
                                         {
                                            "exists":{
                                               "field":"availability.keyword"
                                            }
                                         }
                                      ]
                                   }
                                }
                             ]
                          }
                        }
                      ]
                    }
                  },
                  "size":21,
                  "_source":{
                    "includes":[
                        "*"
                    ],
                    "excludes":[
                        
                    ]
                  },
                  "from":0,
                  "sort":[
                    {
                        "_geo_distance":{
                          "storelatlong":{
                              "lat":"34.2024698",
                              "lon":"-118.8741429"
                          },
                          "order":"asc",
                          "unit":"mi",
                          "mode":"min",
                          "distance_type":"arc",
                          "ignore_unmapped":true
                        }
                    }
                  ]
              };
              if(step >= 3){
                  if(user.daysOfWeek && user.daysOfWeek.length > 0){
                    var selectedDays = getDayIndicators(user.daysOfWeek);
                  }else{
                    var selectedDays = [1,2,3,4,5,6,7];
                  }
                  reqData = getQueryForDaysOfWeek(selectedDays,avlInstArr,instArr);
                  if(step >= 4 && user.timeOfDay && user.timeOfDay.length > 0){
                    reqData = getQueryForTimeOfDay(selectedDays,avlInstArr,instArr,user.timeOfDay);
                  }
              }
              window.parent.postMessage({event: 'LessonStep', data: {user: JSON.parse(JSON.stringify(user)), step: step+1, stepName: steps[step].title}}, '*')
          }
        
          axios.post('https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api/iquery', reqData, axiosConfig)
          .then(res => {
            if (res.data && res.data.hits && res.data.hits.total ) {
              var vCount = res.data.hits.total.value;
              setAddedInd('+');
              if(vCount > 1000){
                vCount = 1000;
              }else if(vCount < 1000 && vCount > 100){
                vCount = (vCount- (vCount%100));
              }else if(vCount < 100 && vCount > 10){
                vCount = (vCount- (vCount%10));
              }else if(vCount <= 10 && vCount > 5){
                vCount = (vCount- (vCount%5));
              }else if(vCount <= 5 && vCount > 0){
                setAddedInd('');
              }
              setInstructorCountInd(vCount);
            }
          })
          .catch(err => {
              console.error(err);
             
            }
          );
    }
    function getDayIndicators(daysSelected){
      var result = [];
      for(var val of daysSelected){
          var dayEq = 0;
          if(val=='monday' || val=='2')
          {
              dayEq = 2;
          }
          else if(val=='tuesday' || val=='3')
          {
              dayEq = 3;
          }
          else if(val=='wednesday' || val=='4')
          {
              dayEq = 4;
          }
          else if(val=='thursday' || val=='5')
          {
              dayEq = 5;
          }
          else if(val=='friday' || val=='6')
          {
              dayEq = 6;
          }
          else if(val=='saturday' || val=='7')
          {
              dayEq = 7;
          }
          else if(val=='sunday' || val=='1')
          {
              dayEq = 1;
          }
          result.push(dayEq);
      }
      return result;
    }
    function QuestionSwitchCase(props){
        var question = '';
        if(props.studentInd === 'myself'){
            question = props.formContent.title;
        }else if(props.studentInd === 'child'){
            question = props.formContent.titleChild;
        }else if(props.studentInd === 'someone'){
            question = props.formContent.titleSomeOne;
        }else{
            question = props.formContent.title;
        }
        return (<h3 className="color-dark" style={{fontWeight : 500, fontSize: '1.825em'}}>{question} </h3>);
    }
    return(
        <div>
            <div className={`ldOverlay ${rLoading ? "dBlock" : "dNone"}`}>
              <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
                  <div />
                </div>
            </div>
            <button
                    className="close"
                    type="button"
                    onClick={() => {props.modalFn(false)}}
                    style={{padding: '15px 15px 0 0'}}
                    >
                    <span>×</span>
                    </button>
            <div className="modal-header no-border-header text-center" style={{minHeight: '150px', maxHeight: '170px'}}>
                    
                    <Row className="justify-content-md-center">
                      <Col xs="12" md="12" className="ml-auto mr-auto">
                        <p>Step {step+1} of {steps.length}</p>
                      </Col>
                      <Col xs="8" md="8" className="ml-auto mr-auto">
                        <Progress max={steps.length} value={step} barClassName="progress-bar-info"/>
                      </Col>
                    </Row>
                    <div>
                    <div className="top-count-ind">
                        {instructorCountInd && instructorCountInd > 0 ?
                        // commenting out link for demo
                        // <span>Great! We've got <a className="top-count-ind-link" href="!#" onClick={(e) => {handleJumpToListing(e)}}>
                        //         {instructorCountInd}{addedInd} {instrumentSel} instructors
                        // </a> waiting for you!
                        // </span>
                        <span>Great! We've got {instructorCountInd}{addedInd} {instrumentSel} instructors waiting for you!
                        </span>
                        :
                        <></>
                        }
                    </div>
                    <QuestionSwitchCase studentInd={user.lessonFor} formContent={steps[`${step}`]}/>
                    {
                        steps[`${step}`].optional ?
                            <p>Optional</p>
                            :
                            ""
                    }
                    </div>
                </div>
            
            <div className="modal-body reg-modal">
                <div className="reg-modal-content">{steps[`${step}`].content}</div>
            </div>
            <div className="modal-footer no-border-footer">
                
            </div>
        </div>
    );
}
export default HeroRegModal;