import React from "react";
import {
    FormGroup, Input, Button, Label, FormFeedback, Form, Row, Col
} from "reactstrap";
import axios from 'axios';
import config from '../../config.js';

function InstructorContactModal(props) {

    var site = localStorage.getItem('siteSource');

    const[sLoading, setSLoading] = React.useState(false);
    const[ackMsg, setAckMsg] = React.useState('');
    const[formValid, setFormValid] = React.useState(false);
    const[closeForm, setCloseForm] = React.useState(false);

    const[validated, setValidated] = React.useState(true);

    const[nameInValid, setNameInValid] = React.useState('');
    const[emailInValid, setEmailInValid] = React.useState('');
    const[msgInValid, setMsgInValid] = React.useState('');
    const[phoneInvalid, setPhoneInvalid] = React.useState('');

    const[uName, setUName] = React.useState('');
    const[email, setEmail] = React.useState('');
    const[phone, setPhone] = React.useState('');
    const[message, setMessage] = React.useState('');
    const[storeId, setStoreId] = React.useState(props.instructor.storeNumber);
    const[smsConsent, setSmsConsent] = React.useState(true);

    const ackSuccess = "Thank you for your interest. We'll be in touch with you shortly.";
    const ackFailure = "Uh oh! Something wen't wrong.";
    const errorCheck = "Please check all the fields in the form.";

    React.useEffect(() => {
      if(nameInValid === 'valid' && (emailInValid === 'valid' || phoneInvalid === 'valid')){
        setFormValid(true);
      }else{
        setFormValid(false);
      }
    }, [nameInValid, emailInValid, phoneInvalid]);

    const handleSubmit = (e) => {
      e.preventDefault();
      setSLoading(true);
      var passStoreId = storeId;
        if(process.env.REACT_APP_WORK_ENV === 'test'){
          passStoreId = '2000';
        }
      var reqData = {
        id: new Date().getTime(),
        user: uName,
        email: email,
        phone: phone,
        message: message,
        smsConsent: smsConsent,
        instructor: props.instructor.name,
        instId: props.instructor.id,
        status: 'submitted',
        brand: site,
        type : 'instructorContact',
        store : passStoreId,
        instructorgcemail: props.instructor.email.toLowerCase(),
        storeemailaddress: props.instructor.storeemailaddress.toLowerCase(),
        storephonenumber: props.instructor.storephonenumber
      }
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "x-api-key": "7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb"
        }
      };

      var atcEvent = {
        'event' : 'Contact',
        'data': props.instructor.name
    };
    window.parent.postMessage(atcEvent, '*');

      axios.post('https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api/contact', reqData, axiosConfig)
      .then(res => {
        clearFormFields();
        setSLoading(false);
        setAckMsg('ackSuccess');
        setCloseForm(true);
        setTimeout(() => {props.modalFn(false)}, 2000);
        let axiosConfigEmail = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "x-api-key": "8M4ogVDXVKHSc66KCZbX9NxeLsQRPcS6zkdSLHB4"
            }
          };
          var reqDataEmail = {
                  id: JSON.parse(res.data)._id,
                  brand: 'ma'
          }
          axios.post('https://jrf6phar18.execute-api.us-west-2.amazonaws.com/default/sg', reqDataEmail, axiosConfigEmail)
                .then(res => {
          });
      })
      .catch(err => {
          console.error(err);
          setSLoading(false);
          setAckMsg('ackFailure');
        }
      );
    }

    const validateFileds = (e) => {
      const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var phoneNoRex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var field = e.target.name;
      var value = e.target.value;
      switch(field){
        case "custName":
          if(value && value.length > 0){
            setNameInValid("valid");
          }else{
            setNameInValid("invalid");
          }
          break;
        case "custEmail":
          if(emailRex.test(value)){
            setEmailInValid("valid");
            setPhoneInvalid('');
          }else{
            if(phoneNoRex.test(phone)){
              setEmailInValid("");
            }else{
              setEmailInValid("invalid");
              setPhoneInvalid("invalid");
            }
          }
          break;
        case "custPhone":
          if(phoneNoRex.test(value)){
            setPhoneInvalid("valid");
            setEmailInValid('');
          }else{
            if(emailRex.test(email)){
              setPhoneInvalid("");
            }else{
              setPhoneInvalid("invalid");
              setEmailInValid("invalid");
            }
          }
          break;
        case "custMsg":
          if(value && value.length > 0){
            setMsgInValid("valid");
          }else{
            setMsgInValid("invalid");
          }
          break;
        default:
      }

    }

    const clearFormFields = () => {
      setUName('');
      setEmail('');
      setPhone('');
      setMessage('');
    }

    const handleChange = (e) => {
      // only show validation errors after user tabs out of field
      if (e.target.classList.contains('is-invalid')) {
        validateFileds(e);
      }
    }

    const hasImage = props.instructor.imagename && (props.instructor.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
    const hideInstructorName = !props.instructor.shortbio && !hasImage;
    
    return(
        <div>
            <div className={`ldOverlay ${sLoading ? "dBlock" : "dNone"}`}>
              <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
                  <div />
                </div>
            </div>
            <div className="modal-header no-border-header text-center">
                  <button
                    className="close"
                    type="button"
                    onClick={() => props.modalFn(false)}
                  >
                    <span>×</span>
                  </button>
                  <h3 className="modal-title text-center contact-modal">Get In Touch</h3>
                </div>
                <div className="modal-body">
                  <h6 style={{display: ackMsg ? '': 'none'}} className="ackSec">
                    <span className={`subAck ${ackMsg}`}>{ackMsg === 'ackSuccess' ? ackSuccess : ackFailure}</span>
                  </h6>
                  <h6 style={{display: validated ? 'none': ''}} className="errSec">
                    <span className={`subAck ackFailure`}>{errorCheck}</span>
                  </h6>
                  <Form className={`${closeForm ? "force-hide" : "force-show"}`}>
                  <FormGroup>
                  <Input placeholder={hideInstructorName ? "Music & Arts Instructor" : props.instructor.name} type="text" value={hideInstructorName ? "Music & Arts Instructor" : props.instructor.name} readOnly/>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Name (required)"
                      type="text"
                      invalid={nameInValid === 'invalid'}
                      value={uName}
                      name="custName"
                      onBlur={(e) => validateFileds(e)}
                      onChange={(e) => {validateFileds(e); setUName(e.target.value)}}
                      
                    />
                    <FormFeedback>Please enter your name</FormFeedback>
                  </FormGroup>
                  <hr />
                  <FormGroup>
                    <Input
                      placeholder="Email address"
                      type="email"
                      invalid={emailInValid === 'invalid'}
                      name="custEmail"
                      value={email}
                      onBlur={(e) => validateFileds(e)}
                      onChange={(e) => {handleChange(e); setEmail(e.target.value)}}
                    />
                  </FormGroup>
                  <FormGroup className="align-center">
                    <span>OR</span>
                  </FormGroup>
                  <FormGroup>
                    <Input
                            type="text"
                            name="custPhone"
                            id="phoneNumber"
                            placeholder="Phone number"
                            invalid={phoneInvalid === 'invalid'}
                            value={phone}
                            onBlur={(e) => validateFileds(e)}
                            onChange={(e) => {handleChange(e); setPhone(e.target.value)}}
                            />
                  </FormGroup>
                  <FormGroup>
                      <Col className="align-center">
                        <div class={`fw500 invalid-feedback ${phoneInvalid === 'invalid' || emailInValid === 'invalid' ? 'force-show' : ''}`}>Please enter a valid phone number or email address</div>
                      </Col>
                    </FormGroup>
                    <hr />
                  <FormGroup>
                    <Input type="textarea" name="custMsg" id="message" 
                    placeholder="Please type your message here..."
                    invalid={msgInValid === 'invalid'}
                    value={message}
                      onChange={(e) => {setMessage(e.target.value)}}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="smsConsent">{config.contactConsent}</Label>
                  </FormGroup>
                  <Row>
                    <Col>
                      <Button type="reset" className="btn-round pull-left" color="default" onClick={() => props.modalFn(false)}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn-round pull-right" color="danger" onClick={(e) => handleSubmit(e)} disabled={!formValid}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  </Form>
                </div>
        </div>
    );
}

export default InstructorContactModal;