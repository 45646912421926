import React from 'react';
import axios from 'axios';
import moment from 'moment';

const holidays = ["01-01-2022", "04-17-2022", "05-30-2022", "07-04-2022", "09-05-2022", "11-24-2022", "12-25-2022"];

export const getToggledElements = (currentArray, targetValue) => {
    var resultArray = [];
    if(currentArray && currentArray.length > 0){
        resultArray = currentArray;
        var entInd = resultArray.indexOf(targetValue);
        if(entInd > -1){
            resultArray.splice(entInd, 1);
        }else{
            resultArray.push(targetValue);
        }
    }else{
        resultArray.push(targetValue);
    }
    return resultArray;
}

const getQuery = () => {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
};
  
export const getQueryStringVal = (key) => {
    return getQuery().get(key);
};

export const getStores = (zip, distance) => {
    const liveApiYextUrl = `https://liveapi.yext.com/v2/accounts/me/locations/geosearch?api_key=79ddf705a35f1ffb6fd166a76bf121ac&v=20190506&location=${zip}&radius=${distance}&&filters=[{'closed':'false'}]`;
    return axios.get(liveApiYextUrl).then(function(res) {
        if(res.data) {
            var response = res.data.response;
            var filteredStores = response.locations.filter(store => !store.id.includes('_'));
            var filteredDistances = response.locationDistances.filter(store => !store.id.includes('_'));
            if(filteredStores.length > 3 && filteredDistances.length > 3){
                filteredStores = filteredStores.slice(0, 3);
                filteredDistances = filteredDistances.slice(0, 3);
            }
            return {
                storesList: filteredStores, selectedStore: filteredStores[0], locationDistances: filteredDistances
            };
        }
    })
};

export function formatStoreInfo(selectedStore) {    
    if (selectedStore && selectedStore.id) {
        let phoneNum = selectedStore.phone;
        const cleaned = ('' + phoneNum).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        phoneNum = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
        return (
            <div>
                <p>{selectedStore.address}</p>
                <p>{selectedStore.city + ', ' + selectedStore.state + ' ' + selectedStore.zip}</p>
                <p>{phoneNum}</p>
            </div>
        )
    } else {
        return;
    }
}

// formatting store hours for display on store selector and confirmation page
export function formatStoreHours(selectedStore) {
    if (selectedStore && selectedStore.id) {
        const hoursString = selectedStore.hours;
        let hoursArray = hoursString ? hoursString.split(','): [];

        hoursArray = hoursArray.map((item, i) => {
            const day = item.slice(2);
            let open = day.slice(0,5);
            open = moment(open, "HH:mm").format("h:mm A");
            let close = day.slice(6);
            close = moment(close, "HH:mm").format("h:mm A");

            const dayObj = {
                open: open, close: close
            };
            return dayObj;
        });

        const sundayHours = `${hoursArray[0].open} - ${hoursArray[0].close}`;
        // todo: check that all weekday hours are the same, if, return in a different format
        const weekdayHours = `${hoursArray[1].open} - ${hoursArray[1].close}`;
        const saturdayHours = `${hoursArray[6].open} - ${hoursArray[6].close}`;

        return (
            <div>
                <p className="store-info-bold">Store Hours</p>
                <p>SUN: {sundayHours ? sundayHours : ''}</p>
                <p>MON-FRI: {weekdayHours ? weekdayHours : ''}</p>
                <p>SAT: {saturdayHours ? saturdayHours : ''}</p>
            </div>
        )
    } else {
        return;
    }
}

export const getStoresFromVC = (zip, distance) => {
   const liveApiYextUrl = `${process.env.REACT_APP_ATG_HOST}/storefrontapi/stores/closest?zipCode=${zip}&radius=${distance}&take=5`;
   return axios.get(liveApiYextUrl).then(function(res) {
       if(res.data) {
           var response = res.data;
           var filteredStores = res.data;
           var filteredDistances = response.filter(store => !store.id.includes('_'));
           if(filteredStores.length > 3 && filteredDistances.length > 3){
               filteredStores = filteredStores.slice(0, 3);
               filteredDistances = filteredDistances.slice(0, 3);
           }
           for(var filtStore of filteredStores){
            var vStoreNum = ''+filtStore.storeNumber;
            if(vStoreNum.length === 2){
               vStoreNum = `00${vStoreNum}`;
            }
            filtStore.fullStoreId = vStoreNum;
           }
           return {
               storesList: filteredStores, selectedStore: filteredStores[0], locationDistances: filteredDistances
           };
       }
   })
};

export function formatStoreInfoFromVC(selectedStore) {    
   if (selectedStore && selectedStore.id) {
       let phoneNum = selectedStore.phone;
       var cleaned = ('' + phoneNum).replace(/\D/g, '');
       if(cleaned.startsWith('1') && cleaned.length === 11){
         cleaned = cleaned.replace('1', '');
       }
       const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
   if (match) {
       phoneNum = '(' + match[1] + ') ' + match[2] + '-' + match[3];
   }
       return (
           <div>
               <p>{selectedStore.address.line1}</p>
               <p>{selectedStore.address.city + ', ' + selectedStore.address.state + ' ' + selectedStore.address.zip}</p>
               <p>{phoneNum}</p>
           </div>
       )
   } else {
       return;
   }
}

// formatting store hours for display on store selector and confirmation page
export function formatStoreHoursFromVC(selectedStore) {
   if (selectedStore && selectedStore.id && selectedStore.openingHours && selectedStore.openingHours.length > 0) {

      var hoursElements = selectedStore.openingHours.map((hoursEntry, i) => {
         return(
            <p key={`${selectedStore.fullStoreId}_hrs_${i}`}>{hoursEntry.day.toUpperCase()}: {hoursEntry.openingHour} - {hoursEntry.closingHour}</p>
         );
      });
       

       return (
           <div>
               <p className="store-info-bold">Store Hours</p>
               {hoursElements}
           </div>
       )
   } else {
       return;
   }
}

export function validateFormFields(value, type){
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var phoneNoRex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var result = 'invalid';
    switch(type){
      case "custName":
        if(value && value.length > 0){
            result = "valid";
        }
        break;
      case "custEmail":
        if(emailRex.test(value)){
            result = "valid";
        }
        break;
      case "custPhone":
        if(phoneNoRex.test(value)){
            result = "valid";
        }
        break;
      case "custMsg":
        if(value && value.length > 0){
            result = "valid";
        }
        break;
      default:
    }
    return result;
}

export function getSelectedWeekdayCount(selDate){
    var selectedDate = moment(selDate);
    var selWeekday = selectedDate.day();
    var selectedMonth = selectedDate.month();

    var count = 1;

    while(selectedDate.month() === selectedMonth){
        selectedDate.add(1, 'd');
        var selDtStr = selectedDate.format("MM-DD-YYYY");
        if(selectedDate.day() === selWeekday && selectedDate.month() === selectedMonth && !holidays.includes(selDtStr)){
            count++;
        }
    }

    return count;
}

export function getQueryForDaysOfWeek(selectedDays,avlInstArr,instArr){
    var reqData = {
        "query":{
           "bool":{
              "must":[
                 {
                    "bool":{
                       "must":[
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){return true;}}return false;}",
                                                     "params":{
                                                        "min":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "terms":{
                                "activityFull.keyword":avlInstArr
                             }
                          },
                          {
                             "terms":{
                                "instruments.keyword":instArr
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "exists":{
                                         "field":"availability.keyword"
                                      }
                                   }
                                ]
                             }
                          }
                       ]
                    }
                 }
              ]
           }
        },
        "size":21,
        "_source":{
           "includes":[
              "*"
           ],
           "excludes":[
              
           ]
        },
        "from":0,
        "sort":[
           {
              "_geo_distance":{
                 "storelatlong":{
                    "lat":"34.2024698",
                    "lon":"-118.8741429"
                 },
                 "order":"asc",
                 "unit":"mi",
                 "mode":"min",
                 "distance_type":"arc",
                 "ignore_unmapped":true
              }
           }
        ]
     };

     return reqData;
}

export function getQueryForTimeOfDay(selectedDays,avlInstArr,instArr,timeArr){
    var minMax = getMinMaxForTimeRange(timeArr);
    var reqData = {
        "query":{
           "bool":{
              "must":[
                 {
                    "bool":{
                       "must":[
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){return true;}}}}return false;}",
                                                     "params":{
                                                        "min":minMax[0],
                                                        "max":minMax[1],
                                                        "dayOfWeekCheckedBoxesI":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){return true;}}return false;}",
                                                     "params":{
                                                        "min":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "terms":{
                                "activityFull.keyword": avlInstArr
                             }
                          },
                          {
                             "terms":{
                                "instruments.keyword": instArr
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "exists":{
                                         "field":"availability.keyword"
                                      }
                                   }
                                ]
                             }
                          }
                       ]
                    }
                 }
              ]
           }
        },
        "size":21,
        "_source":{
           "includes":[
              "*"
           ],
           "excludes":[
              
           ]
        },
        "from":0,
        "sort":[
           {
              "_geo_distance":{
                 "storelatlong":{
                    "lat":"34.2024698",
                    "lon":"-118.8741429"
                 },
                 "order":"asc",
                 "unit":"mi",
                 "mode":"min",
                 "distance_type":"arc",
                 "ignore_unmapped":true
              }
           }
        ]
     };

     return reqData;
}

function getMinMaxForTimeRange(tod){
            var min = 23;
            var max = 0;
            if(tod.includes('morning') || tod.includes('1'))
            {
                if(5<min)
                {
                    min=5;
                }
                if(12>max)
                {
                    max=12;
                }
            }
            if(tod.includes('afternoon') || tod.includes('2'))
            {
                if(13<min)
                {
                    min=13;
                }
                if(16>max)
                {
                    max=16;
                }
            }
            if(tod.includes('evening') || tod.includes('3'))
            {
                if(17<min)
                {
                    min=17;
                }
                if(23>max)
                {
                    max=23;
                }
            }
            if(tod.length===0 || (tod.includes('on') && tod.length === 1))
            {
                min=0;
                max=23;
            }

            return [min, max];
}