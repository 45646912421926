import React from 'react';
import{
    Card, CardBody, CardTitle, CardFooter, Button
} from "reactstrap";
import '../../assets/css/custom-kit.css';
import { Envelope } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import useWindowDimensions from '../../gc-comps/utility/useWindowDimensions.js';

var lessonSelectInstructorEvent = {
  'event' : 'LESSON_SELECT_INSTRUCTOR'
};

function InstructorListCardAltOne(props){
    var instruments = props && props.instData && props.instData.instruments ? props.instData.instruments.map(instruments => instruments.key) : [];
    var instnameInd = props && props.instData && props.instData.name ? props.instData.name.toLowerCase().replace(' ', '-') : '';
    const { height, width } = useWindowDimensions();
    const[toggleContactBtn, setToggleContactBtn] = React.useState(false);

    React.useEffect(() => {
        if(width > 767 && width < 1300){
            setToggleContactBtn(true);
        }else{
            setToggleContactBtn(false);
        }
    }, [width]);

    const history = useHistory();

    const routeChange = (e, firstname, lastname) =>{
      var fnStr = firstname.toLowerCase(); 
      var lnStr = lastname.toLowerCase();
          let path = `https://stores.musicarts.com/instructors/${fnStr}-${lnStr}`; 
          //history.push(path);
          window.open(path, '_blank');
        }
    const storeAddress = props.instData.storeaddress2 ? `${props.instData.storeaddress1} ${props.instData.storeaddress2}` : `${props.instData.storeaddress1}`;
    const _30price = props.instData['thirtyprice'] ? `$${props.instData['thirtyprice']}` : null;
    const _60price = props.instData['sixtyprice'] ? `$${props.instData['sixtyprice']}` : null;

    const hasImage = props.instData.imagename && (props.instData.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
    const hideInstructorName = !props.instData.shortbio && !hasImage;
    const webpage = `/instructor-profile?id=${props.instData.instructorId}`;
    
    return(
        <Card className="card-profile">
                  <CardBody>
                    <div className="card-avatar">
                    {!hideInstructorName ? 
                      <a onClick={(e) => {e.preventDefault();  props.profileModalTrigger(e, props.instData)}}>
                        {props.instData && props.instData.imagename ?  <img
                          alt="avatar"
                          src={props.instData.imagename}
                        /> : <img
                                   alt="avatar"
                                   src="https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png"
                                 />

                        }
                      </a> : <img
                                   alt="avatar"
                                   src="https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png"
                                 />}
                    </div>
                    <div className="card-profile_prices">
                          <div className="lesson-price-panel --preview">
                            <div className="lesson-price-panel_row">
                              <div className="lesson-price-panel_col">
                                <p className="panel_bullet-item">{_30price ? "30 Min" : " "}</p>
                              </div>
                              <div className="lesson-price-panel_col">
                                <p className="lesson-price-panel_price">{_30price ? _30price : " "}</p>
                              </div>
                            </div>
                            <div className="lesson-price-panel_row">
                              <div className="lesson-price-panel_col">
                                <p className="panel_bullet-item">{_60price ? "60 Min" : " "}</p>
                              </div>
                              <div className="lesson-price-panel_col">
                                <p className="lesson-price-panel_price">{_60price ? _60price : " "}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!hideInstructorName ? 
                            <a className="card-profile_body" onClick={(e) => {e.preventDefault();  props.profileModalTrigger(e, props.instData)}}>
                            <h5 className="card-category inst-list-name">{hideInstructorName ? 'Music & Arts Instructor':props.instData.name}</h5>
                            <div className="card-profile_location">
                            <div className="card-profile_location_icon"></div>
                            <p>{`Music & Arts ${props.instData.storecity} #${props.instData.storeNumber}`}<br/>
                            {storeAddress}<br/>{`${props.instData.storecity}, ${props.instData.storestate}`}<br/>
                            Distance : {parseFloat(props.instData.fields.distance).toFixed(1)} mi</p>
                                </div>
                            </a>
                            :
                            <>
                              <h5 className="card-category inst-list-name">{hideInstructorName ? 'Music & Arts Instructor':props.instData.name}</h5>
                              <div className="card-profile_location">
                              <div className="card-profile_location_icon"></div>
                              <p>{`Music & Arts ${props.instData.storecity} #${props.instData.storeNumber}`}<br/>
                              {storeAddress}<br/>{`${props.instData.storecity}, ${props.instData.storestate}`}<br/>
                              Distance : {parseFloat(props.instData.fields.distance).toFixed(1)} mi</p>
                                  </div>
                            </>
                        }
                    
                        <div className="shorten-bio">
                            <p className="card-description color-dark my-auto">
                                {props.instData.shortbio ? props.instData.shortbio.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/&amp;/g, ' & ').replace(/&rsquo;/g, "'") : null}
                            </p>
                        </div>
                        
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                        className={`btn btn-secondary pull-left ${toggleContactBtn? "ind-btn-tgl" : ""}`}
                        color="white"
                        href="/instructor-profile"
                        name={props.instData.name}
                        style={{margin:'auto'}}
                        onClick={(e) => {e.preventDefault(); props.contactModalTrigger(e, props.instData)}}
                        >
                        CONTACT ME
                    </Button>
                    <Button
                        className={`btn btn-primary pull-right ${toggleContactBtn? "ind-btn-msg-tgl" : ""}`}
                        color="danger"
                        href="!#"
                        name={props.instData.name}
                        onClick={(e) => {e.preventDefault(); window.parent.postMessage(lessonSelectInstructorEvent, '*'); props.bookModalTrigger(e, props.instData)}}
                        >
                        BOOK NOW
                    </Button>
                  </CardFooter>
                </Card>
    );
}

export default InstructorListCardAltOne;