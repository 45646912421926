import React from 'react';
import {
  FormGroup, Form, Input, Modal, FormFeedback, Spinner
} from "reactstrap";
import ActionButtonsFrag from './frags/ActionButtonsFrag.js';
import { useHistory } from "react-router-dom";
import Geocode from "react-geocode";


const StepEighteenGeneral = ({step, setStep, user, setUser, optional}) => {
    var zipRex = /^\d{5}(-\d{4})?$/;
    const[btnText, setBtnText] = React.useState(optional ? "Skip" : "Next");
    const[showSpinner, setShowSpinner] = React.useState(false);
    const[zipInValid, setZipInValid] = React.useState('');
    const handleUser = ({ target }) => {
      const value = target.value;

        if(target.value.length > 0){
          setBtnText("Next");
          if (value === "2000" || zipRex.test(value)) {            
            Geocode.setApiKey('AIzaSyBCyr74olCLoRdjYFILeenS6oFSjxJQ2yw');
            Geocode.setLanguage("en");
            Geocode.setRegion("us");
            Geocode.setLocationType("APPROXIMATE");
            Geocode.enableDebug();
            const response = Geocode.fromAddress(target.value === "2000" ? 91362 : target.value).then(
              (response) => {
                if(response.status !== 'ZERO_RESULTS' || target.value == "2000") {
                    const { lat, lng } = response.results[0].geometry.location
                    setUser({
                      type: 'LESSON_ZIP_CODE',
                      payload: { latitude: lat, longitude: lng },
                    });
                    setZipInValid("valid");
                }
                else
                {
                  setZipInValid("invalid");
                }
            },
            (error) => {
                setZipInValid("invalid");
            });
            setZipInValid("");
          }else{
             // setZipInValid("invalid");
          }
        }else{
          setZipInValid("");
          setBtnText("Skip");
        }
//        }
//        else
//        {
        setUser({
          type: 'LESSON_ZIP_CODE',
          payload: { [target.name]: target.value },
        });
//        }
      }
    React.useEffect(() => {
      //callForLocation();
    },[]
    );
    const handleContinue = (e) => {
        e.preventDefault();
        if (zipInValid === "" || zipInValid === "valid" || user.zipCode === "2000") {
          localStorage.setItem('userSelections', JSON.stringify(user));
          var atcEvent = {
            'event' : 'LESSON_SELECT_ZIP',
            'data': user.zipCode
        };
        window.parent.postMessage(atcEvent, '*');
          routeChange();
        }
    }
    Geocode.setApiKey('AIzaSyBCyr74olCLoRdjYFILeenS6oFSjxJQ2yw');
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setLocationType("APPROXIMATE");
    Geocode.enableDebug();
    const findLocation = (e) => {
      e.preventDefault();
      locationApiCall(); 
    }
    const callForLocation = () => {
      if(navigator && navigator.permissions){
        navigator.permissions.query({name:'geolocation'}).then(function(result) {
          if (result.state === 'granted') {
            locationApiCall();
          } else if (result.state === 'prompt') {
            locationApiCall();
          } else if (result.state === 'denied') {
            console.error("Error Code = " + result);
          }
        });
      }
    }

    const locationApiCall = () => {
      setShowSpinner(true);
      navigator.geolocation.getCurrentPosition(function(position) {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            const address = response.results[0].formatted_address;
            for (let b = 0; b < response.results.length; b++) {
              for (let i = 0; i < response.results[b].address_components.length; i++) {
                for (let j = 0; j < response.results[b].address_components[i].types.length; j++) {
                  switch (response.results[b].address_components[i].types[j]) {
                    case "postal_code":
                      var target = {
                        name : 'zipCode',
                        value : response.results[b].address_components[i].long_name
                      }
                      handleUser({target: target});
                      var atcEvent = {
                        'event' : 'LESSON_SEARCH_FOR_LOCATION',
                        'data': target.value
                    };
                    if(process.env.REACT_APP_WORK_ENV === 'test'){
                        console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
                    }
                    window.parent.postMessage(atcEvent, '*');
                      break;
                    default:
                      break;
                  }
                }
              }  
            }
            setShowSpinner(false);
          },
          (error) => {
            console.error(error);
            setShowSpinner(false);
          }
        );
      },
      function(error) {
        console.error("Error Code = " + error.code + " - " + error.message);
        setShowSpinner(false);
      }
      );
    }
    const history = useHistory();

    const routeChange = () =>{ 
      let path = `inst-listing`; 
      history.push(path);
    }
    const handleSubmit = (e) => {

        e.preventDefault();
        if(zipInValid !== 'invalid' || user.zipCode === '2000') {
          document.getElementById('zipcode-submit').click();
        }      
      }


    const handleBlur = (e) => {
      if(!zipRex.test(e.target.value)) {
        setZipInValid("invalid");
      }
    }
    return(
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="reg-modal-form" style={{height: '200px'}}>
            <FormGroup>
              <Input id="zipcode-modal-field" value={user.zipCode} placeholder="Type your zip code" type="text" onChange={handleUser} onBlur={(e)=>handleBlur(e)} name="zipCode" invalid={zipInValid === 'invalid'}/>
              <FormFeedback> {user.zipCode === "2000" ? null : "Please enter a valid zip code"}</FormFeedback>
            </FormGroup>
            {showSpinner ?
            <div style={{textAlign: 'center', fontWeight: '600'}}>
            <Spinner color="primary" />
              </div> :
            <div style={{textAlign: 'center', fontWeight: '600'}}>
              <span>OR</span><br/>
                <a href="!#" style={{fontWeight: '600'}} onClick={(e) => findLocation(e)} className="clr-alt-red">Use my location</a>
                <p style={{fontSize: '12px', fontWeight: '500'}}>We believe pairing students to teachers in the same geographic region creates the best results. This also means it's easy to switch from online to in-store or vice-versa!</p>
              </div> }
          </div>  
          <ActionButtonsFrag 
            setStep={setStep} 
            step={step} 
            handleContinue={handleContinue} 
            id="zipcode-submit" 
            continueBtnText={btnText} 
            disabled={zipInValid === 'invalid' && user.zipCode !== '2000'}
          />
        </Form>
    );
} 

export default StepEighteenGeneral;
